import { useContext, useEffect, useState } from 'react';
import type {
  TkbManualSortDetailsQuery,
  TkbManualSortDetailsQueryVariables,
  GuideContentArticleForMessageQuery,
  GuideContentArticleForMessageQueryVariables,
  KbArticleViewFragment,
  PreviousNextManualSortedArticlesQuery,
  PreviousNextManualSortedArticlesQueryVariables
} from '../../../types/graphql-types';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import TkbManualSortDetails from './../../guides/ManualSortArticlesAction/TkbManualSortDetails.query.graphql';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import guideContentArticleForMessageQuery from '../../guides/GuideNavigation/GuideContentArticleForMessage.query.graphql';
import type { Tkb, GuideContentArticle } from '@aurora/shared-generated/types/graphql-schema-types';
import {
  EndUserPages,
  EndUserPathParams,
  EndUserQueryParams
} from '@aurora/shared-types/pages/enums';
import type { EndUserRouter } from '@aurora/shared-client/routes/useEndUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import useGlobalState, { GlobalStateType } from '@aurora/shared-client/helpers/ui/GlobalState';
import type { RouteInfo } from '@aurora/shared-types/community';
import { extractGuideContentArticleData } from './GuideNavigationHelper';
import previousNextManualSortedArticlesQuery from './PreviousNextManualSortedArticles.query.graphql';
import useKnowledgeBaseProperties from '../../guides/useKnowledgeBaseProperties';

export enum NavigationType {
  GUIDE_NAVIGATION = 'guideNav',
  MANUALLY_SORTED_NAVIGATION = 'manuallySortedNav',
  NONE = 'none'
}

/**
 * Extracts the node id
 *
 * @param previousRouteInfo previous route info object.
 * @param router the end user router
 *
 * @returns node id
 *
 */
function extractNodeId(router: EndUserRouter, previousRouteInfo: RouteInfo) {
  const { route, params } = router.getRouteAndParamsByPath(previousRouteInfo.path);
  let nodeId: string;
  switch (route) {
    case EndUserPages.CategoryPage: {
      nodeId = `category:${params[EndUserPathParams.CATEGORY_ID]}`;
      break;
    }
    case EndUserPages.TkbBoardPage: {
      nodeId = `board:${params[EndUserPathParams.BOARD_ID]}`;
      break;
    }
    case EndUserPages.GroupHubPostPage: {
      nodeId = `grouphub:${params[EndUserPathParams.GROUPHUB_ID]}`;
      break;
    }
    default: {
      break;
    }
  }
  return nodeId;
}

/**
 * Determines the navigation type based on the provided parameters.
 *
 * @param isGuideAvailable - Indicates if the guide is available.
 * @param guideId - The ID of the guide.
 * @param contextNodeId - The ID of the context node.
 * @param nodeId - The node ID to compare with contextNode.id.
 * @param isManualSortOrderAvailable - Indicates if manual sort order is available.
 * @param showManualSortNav - showManualSortNav query param.
 *
 * @returns navigation type
 */
function getNavigationType(
  isGuideAvailable: boolean,
  guideId: string,
  contextNodeId: string,
  nodeId: string,
  isManualSortOrderAvailable: boolean,
  showManualSortNav: boolean
): NavigationType {
  if (isManualSortOrderAvailable && showManualSortNav) {
    return NavigationType.MANUALLY_SORTED_NAVIGATION;
  } else if (isGuideAvailable) {
    if (!!guideId || contextNodeId === nodeId) {
      return NavigationType.GUIDE_NAVIGATION;
    } else if (isManualSortOrderAvailable) {
      return NavigationType.MANUALLY_SORTED_NAVIGATION;
    } else {
      return NavigationType.GUIDE_NAVIGATION;
    }
  } else if (isManualSortOrderAvailable) {
    return NavigationType.MANUALLY_SORTED_NAVIGATION;
  }
  return NavigationType.NONE;
}

interface GuideNavigationDataForArticle {
  /**
   * Navigation Type of the current guide. Can be one of GUIDE_NAVIGATION, MANUALLY_SORTED_NAVIGATION or NONE.
   */
  navigationType: NavigationType;
  /**
   * The article which is being fetched for navigation purpose.
   */
  guideContentArticle?: GuideContentArticle | null;
  /**
   * Previous article to the current article in context.
   * This can be either previous article in the current guide, or if the context article is the first in current guide
   * this will be the last article of the previous guide.
   * Can be null if we're at the first article of the first guide in the given node.
   */
  previousArticle: KbArticleViewFragment | null;
  /**
   * Next article to the current article in context.
   * This can be either next article in the current guide, or if the context article is the last in current guide
   * this will be the first article of the next guide.
   * Can be null if we're at the last article of the last guide in the given node.
   */
  nextArticle: KbArticleViewFragment | null;
  /**
   * Whether guides and chapters feature is enabled.
   */
  isGuidesAndChaptersEnabled: boolean;
  /**
   * Guide ID of the previous article.
   * Will be the current guide's id if the previous article is on the same guide.
   * Will be previous guide's id if the context article is the first article in the current guide.
   * Can be null if we're at the first article of the first guide on the given node.
   */
  guideIdOfPreviousArticle: string | null;
  /**
   * Guide ID of the next article.
   * Will be the current guide's id if the next article is on the same guide.
   * Will be next guide's id if the context article is the last article in the current guide.
   * Can be null if we're at the last article of the last guide on the given node.
   */
  guideIdOfNextArticle: string | null;
}

/**
 * Hook to determine the navigation panel to display and guide content if available.
 *
 * @param module The module
 *
 * @author Amit Agrawal
 */
export default function useGuideNavigationForArticle(
  module: NodeModule | string
): GuideNavigationDataForArticle {
  const { router } = useEndUserRoutes();
  const guideId = router.getUnwrappedQueryParam(EndUserQueryParams.GUIDE_ID);
  const showManualSortNavQueryParam = router.getUnwrappedQueryParam(
    EndUserQueryParams.SHOW_MANUAL_SORT_NAV
  );
  const nodeIdParam = router.getUnwrappedQueryParam(EndUserQueryParams.NODE_ID);
  const articleIdParam = router.getUnwrappedQueryParam(EndUserQueryParams.ARTICLE_ID);

  const showManualSortNav = Boolean(showManualSortNavQueryParam);

  const { contextMessage, contextNode } = useContext(AppContext);

  const contextNodeId = nodeIdParam || contextNode.id;
  const contextMessageId = articleIdParam || contextMessage.id;

  const [previousRouteInfo] = useGlobalState(GlobalStateType.PREVIOUS_ROUTE_INFO);

  const [navigationType, setNavigationType] = useState<NavigationType | null>(null);

  const { loading: kbPropertiesLoading, isGuidesAndChaptersEnabled } =
    useKnowledgeBaseProperties(module);

  const { data: guideContentArticleForMessage, loading: guideContentArticleForMessageLoading } =
    useQueryWithTracing<
      GuideContentArticleForMessageQuery,
      GuideContentArticleForMessageQueryVariables
    >(module, guideContentArticleForMessageQuery, {
      variables: {
        messageId: contextMessageId,
        ...(guideId && { guideId }),
        ...(previousRouteInfo && { contextNode: extractNodeId(router, previousRouteInfo) })
      },
      skip: !isGuidesAndChaptersEnabled
    });

  const messageWithGuideInfo = guideContentArticleForMessage?.guideArticle
    ?.article as GuideContentArticle;

  const [selectedGuide] = extractGuideContentArticleData(messageWithGuideInfo);
  const nodeId = selectedGuide?.node?.id;

  const { data: manuallySortedArticlesData, loading: manuallySortedArticlesLoading } =
    useQueryWithTracing<TkbManualSortDetailsQuery, TkbManualSortDetailsQueryVariables>(
      module,
      TkbManualSortDetails,
      {
        variables: {
          id: contextNodeId
        },
        skip: !isGuidesAndChaptersEnabled || (!showManualSortNav && contextNodeId === nodeId)
      }
    );

  const isManualSortOrderAvailable = (manuallySortedArticlesData?.coreNode as Tkb)
    ?.isManualSortOrderAvailable;

  /**
   *  Determines and sets the navigation type based on the current state and context.
   *  It ensures that the navigation type is persisted across rerenders.
   */
  useEffect(() => {
    if (
      !kbPropertiesLoading &&
      !guideContentArticleForMessageLoading &&
      !manuallySortedArticlesLoading &&
      navigationType === null
    ) {
      const navType = getNavigationType(
        Boolean(selectedGuide),
        guideId,
        contextNode?.id,
        nodeId,
        isManualSortOrderAvailable,
        showManualSortNav
      );
      setNavigationType(navType);
    }
  }, [
    contextNode,
    guideContentArticleForMessageLoading,
    guideId,
    contextNode?.id,
    nodeId,
    isManualSortOrderAvailable,
    manuallySortedArticlesLoading,
    navigationType,
    selectedGuide,
    showManualSortNav,
    kbPropertiesLoading
  ]);

  const useManualSortNav = navigationType === NavigationType.MANUALLY_SORTED_NAVIGATION;
  const isGuideNavigation = navigationType === NavigationType.GUIDE_NAVIGATION;

  const { data: previousNextManualSortedArticlesData } = useQueryWithTracing<
    PreviousNextManualSortedArticlesQuery,
    PreviousNextManualSortedArticlesQueryVariables
  >(module, previousNextManualSortedArticlesQuery, {
    variables: {
      messageId: contextMessageId
    },
    skip: !isGuidesAndChaptersEnabled || !useManualSortNav
  });

  let previousArticle = null;
  let nextArticle = null;
  let guideIdOfPreviousArticle = null;
  let guideIdOfNextArticle = null;

  guideIdOfPreviousArticle = selectedGuide?.id;
  guideIdOfNextArticle = selectedGuide?.id;

  if (useManualSortNav) {
    previousArticle =
      previousNextManualSortedArticlesData?.previousNextManuallySortedArticles?.previousArticle;
    nextArticle =
      previousNextManualSortedArticlesData?.previousNextManuallySortedArticles?.nextArticle;
  } else if (isGuideNavigation) {
    previousArticle = guideContentArticleForMessage?.guideArticle?.previousArticle;
    nextArticle = guideContentArticleForMessage?.guideArticle?.nextArticle;
    if (!previousArticle) {
      guideIdOfPreviousArticle = selectedGuide?.previousGuide?.id;
      previousArticle = selectedGuide?.previousGuide?.lastArticle;
    }
    if (!nextArticle) {
      guideIdOfNextArticle = selectedGuide?.nextGuide?.id;
      nextArticle = selectedGuide?.nextGuide?.firstArticle;
    }
  }

  return {
    navigationType: navigationType,
    guideContentArticle: isGuideNavigation ? messageWithGuideInfo : null,
    previousArticle,
    nextArticle,
    isGuidesAndChaptersEnabled,
    guideIdOfPreviousArticle,
    guideIdOfNextArticle
  };
}
