import { useApolloClient } from '@apollo/client';
import type { ApolloQueryResult } from '@apollo/client';
import EmptyState from '@aurora/shared-client/components/common/EmptyState/EmptyState';
import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import ListTitle from '@aurora/shared-client/components/common/List/ListTitle';
import {
  PagerLoadMoreVariant,
  PagerPosition,
  PagerVariant
} from '@aurora/shared-client/components/common/Pager/enums';
import Pager from '@aurora/shared-client/components/common/Pager/Pager';
import usePaginationCursor from '@aurora/shared-client/components/common/Pager/usePaginationCursor';
import { PanelType } from '@aurora/shared-client/components/common/Panel/enums';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useGlobalState, {
  EditorLocation,
  GlobalStateType
} from '@aurora/shared-client/helpers/ui/GlobalState';
import PaginationHelper, {
  PaginationDirection
} from '@aurora/shared-client/helpers/ui/PaginationHelper/PaginationHelper';
import Icons from '@aurora/shared-client/icons';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type {
  Connection,
  Message,
  ReplyMessage
} from '@aurora/shared-generated/types/graphql-schema-types';
import {
  RepliesFormat,
  ConversationStyle
} from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import { getLog } from '@aurora/shared-utils/log';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { ApolloQueryCacheKey, ItemType, MessageViewVariant } from '../../../types/enums';
import type {
  MessageAuthorFragment,
  MessageBoardFragment,
  MessageDescendantsQuery,
  MessageDescendantsQueryVariables,
  MessageReadOnlyIconFragment,
  MessageViewFragment,
  ThreadedReplyListFragment
} from '../../../types/graphql-types';
import PaneledItemList from '../../common/List/PaneledItemList/PaneledItemList';
import type { ItemViewTypeAndProps } from '../../entities/types';
import useEntityViewQuery from '../../useEntityViewQuery';
import useTranslation from '../../useTranslation';
import type { MessageListMenuItemDefinition } from '../MessageListMenu/MessageListMenu';
import MessageListMenu, {
  MessageListMenuItem,
  messageListMenuItemMap
} from '../MessageListMenu/MessageListMenu';
import type { MessageViewStandardProps } from '../MessageView/types';
import messageViewsQuery from '../MessageDescendants.query.graphql';
import type { MessageList } from '../types';
import useMessageSort from '../useMessageSort';
import localStyles from './LinearReplyList.module.pcss';

const log = getLog(module);

interface MessageReplyListProps extends MessageList {
  /**
   * The message to display a list of threaded replies for.
   */
  message: ThreadedReplyListFragment &
    MessageBoardFragment &
    MessageAuthorFragment &
    MessageReadOnlyIconFragment;
  /**
   * Whether to use the empty state if there are no reply messages.
   */
  useEmpty?: boolean;
  /**
   * Whether to use the header for the linear reply list.
   */
  useHeader?: boolean;
  /**
   * Holds the reference of the Message editor.
   */
  editorRef?: React.MutableRefObject<HTMLElement>;
}

/**
 * Displays a linear list of replies for a message. The query to get the replies will be
 * executed as part of rendering this component and will use the ancestorId constraint.
 * The replies rendered via this component will not have indentation based on depth.
 *
 * @constructor
 * @author Subhiksha Venkatesan
 */
const LinearReplyList: React.FC<React.PropsWithChildren<MessageReplyListProps>> = ({
  message,
  viewVariant = {
    type: MessageViewVariant.STANDARD,
    props: {
      useAccordionForReply: false,
      useRepliesCount: false,
      repliesFormat: RepliesFormat.Linear
    }
  },
  listVariant,
  pagerVariant = {
    type: PagerVariant.LOAD_MORE,
    props: {
      position: PagerPosition.START,
      variant: PagerLoadMoreVariant.BORDERED
    }
  },
  pageSize = 10,
  useEmpty = false,
  useHeader = true,
  editorRef
}) => {
  const { authUser, contextUser } = useContext(AppContext);
  const user = contextUser ?? authUser;
  const cx = useClassNameMapper(localStyles);
  const { router } = useEndUserRoutes();
  const autoScroll = router.getUnwrappedQueryParam(EndUserQueryParams.AUTO_SCROLL);
  const isTopic = message.depth === 0;
  const [reloading, setReloading] = useState<boolean>(false);
  const [newMessages, setNewMessages] = useState<Message[]>([]);
  const [messageDeletingState] = useGlobalState(GlobalStateType.MESSAGE_DELETING_STATE);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.LINEAR_REPLY_LIST
  );
  const { conversationStyle } = message?.board;
  const isIdea = conversationStyle === ConversationStyle.Idea;
  const [messageEditingState] = useGlobalState(GlobalStateType.MESSAGE_EDITING_STATE);
  const editorLocation = messageEditingState?.editorLocation;
  const tenant = useContext(TenantContext);
  const client = useApolloClient();

  const { sortMenuItem, loading: repliesSortOrderLoading } = useMessageSort();

  const { sorts } = messageListMenuItemMap[sortMenuItem];

  const afterCursorParam = usePaginationCursor();
  const beforeCursorParam = usePaginationCursor(EndUserQueryParams.BEFORE);
  const repliesSortParam = usePaginationCursor(EndUserQueryParams.SORT_MENU_QUERY_PARAMETER);
  const messageSortParam = usePaginationCursor(
    EndUserQueryParams.MESSAGE_LIST_MENU_QUERY_PARAMETER
  );

  const sortMenuValue = router.getQueryParam(
    EndUserQueryParams.SORT_MENU_QUERY_PARAMETER,
    null
  ) as string;
  const messageType = (message as MessageViewFragment).__typename;

  /**
   * Evicts the cached replies when the user changes the sort -
   * Took the same logic from ThreadedReplyList.tsx
   */
  useEffect(() => {
    if (sortMenuValue) {
      client.cache.evict({ id: `${messageType}:${message.id}`, fieldName: 'descendants' });
      client.cache.gc();
      setNewMessages([]);
    }
  }, [sortMenuValue, client.cache, message.id, messageType]);

  const variables = useMemo(() => {
    return {
      id: message.id,
      useKudosCount: true,
      useTags: viewVariant?.props?.useTags && isTopic,
      useContributors: false,
      useReadOnlyIcon: false,
      attachmentsFirst: 5,
      useRepliesCount: false,
      ...(isIdea && {
        constraints: {
          includeHiddenComments: {
            eq: false
          }
        }
      }),
      ...(isTopic && !afterCursorParam && !beforeCursorParam && { first: pageSize }),
      ...(isTopic && afterCursorParam && { after: afterCursorParam, first: pageSize }),
      ...(isTopic &&
        beforeCursorParam &&
        !afterCursorParam && { before: beforeCursorParam, last: pageSize }),
      sorts,
      useAttachments: false,
      useMedia: false,
      useModerationStatus: true,
      useNodeAncestors: false,
      useNodeHoverCard: false,
      useMessageStatus: true,
      useUserHoverCard: false
    };
  }, [
    afterCursorParam,
    beforeCursorParam,
    isIdea,
    isTopic,
    message.id,
    pageSize,
    sorts,
    viewVariant?.props?.useTags
  ]);

  const cacheKey =
    (isTopic ? ApolloQueryCacheKey.TOPIC_REPLY_LIST : ApolloQueryCacheKey.REPLY_LIST) +
    `:${message.id}:${message.revisionNum}`;

  const queryResult = useEntityViewQuery<
    ItemType.MESSAGE,
    MessageDescendantsQuery,
    MessageDescendantsQueryVariables
  >(module, ItemType.MESSAGE, viewVariant, messageViewsQuery, {
    variables: {
      ...variables
    },
    skip: IdConverter.isOptimistic(tenant, message.id),
    onCompleted: () => {
      if (editorLocation === EditorLocation.CTA) {
        editorRef?.current
          ?.getElementsByClassName('lia-reply-cta-bottom')
          .item(0)
          .scrollIntoView(false);
      }
    },
    cacheKey
  });

  const { refetch } = queryResult;

  const useAccordionForReply =
    viewVariant.type === MessageViewVariant.STANDARD &&
    (viewVariant.props as MessageViewStandardProps).useAccordionForReply;

  const replyHeaderRef = useRef<HTMLDivElement>(null);

  /**
   * Used for scrolling to Reply section when autoScroll=true and topicRepliesSort=postTimeDesc
   */
  useEffect(() => {
    if (autoScroll) {
      setTimeout(() => {
        const replyHeaderElement = replyHeaderRef.current as HTMLElement;
        replyHeaderElement?.scrollIntoView({ behavior: 'smooth' });
        router.removeQueryParam([EndUserQueryParams.AUTO_SCROLL]);
      }, 2000);
    }
  });

  const queryParams = {
    ...(messageSortParam && {
      [EndUserQueryParams.MESSAGE_LIST_MENU_QUERY_PARAMETER]: messageSortParam
    }),
    ...(repliesSortParam && { [EndUserQueryParams.SORT_MENU_QUERY_PARAMETER]: repliesSortParam })
  };

  const onUpdate = useCallback(
    (connection: Connection): MessageDescendantsQuery => {
      return {
        message: {
          id: message.id,
          revisionNum: message.revisionNum,
          __typename: message.__typename,
          descendants: connection,
          parent: (message as ReplyMessage).parent ?? {},
          repliesCount: message.repliesCount
        }
      } as unknown as MessageDescendantsQuery;
    },
    [message]
  );

  const linearPagerVariant = {
    type: isTopic ? PagerVariant.LOAD_MORE_PREVIOUS_NEXT_LINKABLE : pagerVariant.type,
    props: {
      ...pagerVariant.props,
      className: cx('lia-g-mb-10 lia-g-pt-5', {
        'lia-g-ml-md-20': useAccordionForReply
      }),
      queryParams
    }
  };

  useEffect(() => {
    if (!messageDeletingState || newMessages.length === 0) {
      return;
    }

    const deletedMessageFound = newMessages.find(
      element => element.id === messageDeletingState.messageId
    );

    if (!deletedMessageFound) {
      return;
    }

    setNewMessages(previousState =>
      previousState.filter(
        (localMessage: ReplyMessage) =>
          localMessage.id !== messageDeletingState.messageId &&
          !localMessage.ancestors.edges.some(
            ({ node }: { node: Message }) => node.id === messageDeletingState.parentId
          )
      )
    );
  }, [messageDeletingState, newMessages]);

  function onMessagePost(newMessage): void {
    let messageHolder: ReplyMessage = newMessage;
    setNewMessages(previousState => {
      let ifNewMessagePushed = false;
      const tempList = [];
      const updateNewMessageInList = () => {
        if (ifNewMessagePushed === false) {
          tempList.push(newMessage);
          ifNewMessagePushed = true;
        }
      };
      if (previousState.length > 0) {
        previousState?.forEach((localMessage, index) => {
          // If multiple messages with same parent, update it in desc order of posted time.
          if (newMessage.parent.id === (localMessage as ReplyMessage).parent.id) {
            updateNewMessageInList();
            tempList.push(localMessage);
            // If parent message is in list, then update parent message and then child.
            // OR if reached at the last element of the array and message is not yet updated in list.
          } else if (
            newMessage.parent.id === localMessage.id ||
            (index === previousState.length - 1 && ifNewMessagePushed === false)
          ) {
            tempList.push(localMessage);
            updateNewMessageInList();
          } else {
            tempList.push(localMessage);
          }
        });
      } else {
        tempList.push(newMessage);
      }
      if (tempList.length > 1) {
        const updatedRepliesCountList = tempList.reverse().map(message_ => {
          if (messageHolder.parent.id === message_.id) {
            const updatedMessage = {
              ...message_,
              repliesCount: message_.repliesCount ? message_.repliesCount + 1 : 1
            };
            messageHolder = updatedMessage;
            return updatedMessage;
          } else {
            return message_;
          }
        });
        return updatedRepliesCountList.reverse();
      }
      return tempList;
    });
  }

  const [viewVariantState, setViewVariantState] = useState({
    ...viewVariant,
    props: {
      onMessagePost,
      onMessageEdit: newMessage => {
        const updatedMessage: Message = newMessage;
        setNewMessages(previousState => {
          return previousState.map(replyMessage => {
            if (replyMessage.id === updatedMessage.id) {
              return {
                ...replyMessage,
                ...updatedMessage,
                author: replyMessage.author,
                conversation: updatedMessage.conversation
              };
            }
            if (replyMessage.conversation.solved !== updatedMessage.conversation.solved) {
              return {
                ...replyMessage,
                conversation: updatedMessage.conversation
              };
            }
            return replyMessage;
          });
        });
      },
      updateListOnShowMore: newList => {
        setNewMessages(newList);
      },
      newlyPostedMessages: newMessages,
      ...viewVariant.props
    }
  });

  useEffect(() => {
    setViewVariantState(previousState => ({
      type: previousState.type,
      props: {
        ...previousState.props,
        newlyPostedMessages: newMessages
      }
    }));
  }, [newMessages]);

  const handleListMenuChange = useCallback(
    (
      item: MessageListMenuItemDefinition
    ): Promise<void | ApolloQueryResult<MessageDescendantsQuery>> => {
      setNewMessages([]);
      setReloading(true);
      return refetch({
        ...variables,
        after: null,
        before: null,
        sorts: item.sorts
      })
        .finally(() => {
          setReloading(false);
        })
        .catch(error => log.error(error, 'Error loading replies for topic'));
    },
    [refetch, variables]
  );

  /**
   * Render the header with replies count and message menu for reply list.
   */
  const renderHeader = useCallback((): React.ReactElement => {
    const messageListMenuItems: MessageListMenuItem[] = [
      MessageListMenuItem.KUDOS_SUM_WEIGHT_DESC,
      MessageListMenuItem.POST_TIME_ASC,
      MessageListMenuItem.POST_TIME_DESC
    ];
    const paginationHelper =
      isTopic &&
      PaginationHelper.fromQueryResult<MessageDescendantsQuery, MessageDescendantsQueryVariables>(
        queryResult,
        pageSize,
        'message.descendants',
        onUpdate
      );
    const showMorePreviousPagerVariant = isTopic && {
      type: PagerVariant.LOAD_MORE_PREVIOUS_NEXT_LINKABLE,
      props: {
        ...pagerVariant.props,
        paginationDirection: PaginationDirection.PREV,
        queryParams,
        last: pageSize
      }
    };

    return (
      useHeader &&
      message.repliesCount !== 0 && (
        <>
          <div className={cx('lia-replies-header')} ref={replyHeaderRef}>
            <ListTitle as="h3" className={cx('lia-replies-title')}>
              {formatMessage('title', {
                count: message.repliesCount
              })}
            </ListTitle>
            <MessageListMenu
              items={messageListMenuItems}
              onChange={handleListMenuChange}
              defaultItem={sortMenuItem}
              className={cx('lia-replies-sort-menu')}
              queryParameterName={EndUserQueryParams.SORT_MENU_QUERY_PARAMETER}
              alignRight
            />
          </div>
          {message.readOnly && message.repliesCount !== 0 && (
            <div className={cx('lia-read-only-warning rounded-sm')}>
              <Icon
                icon={Icons.LockIcon}
                size={IconSize.PX_16}
                color={IconColor.INFO}
                className={cx('lia-read-only-warning-icon')}
              />
              <small>{formatMessage(`messageReadOnlyAlert:${conversationStyle}`)}</small>
            </div>
          )}
          {isTopic && paginationHelper?.pageInfo?.hasPreviousPage && (
            <Pager
              loadPage={paginationHelper.loadPage}
              pageInfo={paginationHelper.pageInfo}
              variant={showMorePreviousPagerVariant}
              className={'border-bottom-0 lia-g-ml-md-25 lia-g-mb-0'}
            />
          )}
        </>
      )
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cx, formatMessage, handleListMenuChange, sortMenuItem, useHeader]);

  if (textLoading || repliesSortOrderLoading) {
    return null;
  }

  function renderNoReplies(): React.ReactElement {
    return (
      <EmptyState
        icon={Icons.RepliesIcon}
        description={
          message.author.login !== user?.login ? formatMessage('noRepliesDescription') : null
        }
        title={formatMessage('noRepliesTitle')}
      />
    );
  }

  return (
    <PaneledItemList<
      MessageViewFragment,
      ItemType.MESSAGE,
      ItemViewTypeAndProps<ItemType.MESSAGE, MessageViewVariant>,
      MessageDescendantsQuery,
      MessageDescendantsQueryVariables
    >
      type={ItemType.MESSAGE}
      variant={viewVariantState}
      queryResult={queryResult}
      listVariant={listVariant}
      pagerVariant={linearPagerVariant}
      pageSize={pageSize}
      onUpdate={onUpdate}
      panel={PanelType.NONE}
      itemPath="message.descendants"
      className={cx(
        {
          'lia-g-ml-md-25 lia-g-ml-15': viewVariant.props.useRepliesCount
        },
        'lia-g-will-load',
        reloading ? 'lia-g-is-reloading' : 'lia-g-is-loaded'
      )}
      header={renderHeader}
      empty={renderNoReplies}
      useEmpty={useEmpty}
    />
  );
};

export default LinearReplyList;
