import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import { isEventEnded } from '@aurora/shared-client/helpers/forms/DateHelper/DateHelper';
import IconTypes from '@aurora/shared-client/public/static/graphics/processed/enums';
import { ZoomMeetingStatus } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import { getLog } from '@aurora/shared-utils/log';
import React, { useContext, useState } from 'react';
import { useClassNameMapper, Image } from 'react-bootstrap';
import { OccasionZoomMeetingVariant } from '../../../types/enums';
import type {
  ZoomMeetingDetailsQuery,
  ZoomMeetingDetailsQueryVariables
} from '../../../types/graphql-types';
import useTranslation from '../../useTranslation';
import localStyles from './OccasionZoomMeeting.module.pcss';
import zoomMeetingDetails from './ZoomMeetingDetails.query.graphql';
import { getExternalVideoConsentCookie } from '../../common/CookieBanner/CookieHelper';
import VideoConsentBanner from '../../common/VideoConsentBanner/VideoConsentBanner';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';

interface Props {
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Zoom meeting url.
   */
  zoomUrl: string;
  /**
   * Cover Image url.
   */
  coverImageUrl: string;
  /**
   * Occasion End Time
   */
  endTime: string;
  /*
   * Used to identify the variant to render in Sidebar or Cover Image
   * */
  variant: OccasionZoomMeetingVariant;
}

/**
 * Component to join zoom occasions.
 * @constructor
 *
 * @author Vishnu Das
 */
const OccasionZoomMeeting: React.FC<Props> = ({
  className,
  zoomUrl,
  coverImageUrl,
  endTime,
  variant
}) => {
  const HTML_HEAD =
    '<head>' +
    '<meta charset="utf-8"/>' +
    '<link type="text/css" rel="stylesheet" href="https://source.zoom.us/3.11.0/css/bootstrap.css" />' +
    '<link type="text/css" rel="stylesheet" href="https://source.zoom.us/3.11.0/css/react-select.css" />' +
    '<meta name="format-detection" content="telephone=no">' +
    '<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no">' +
    '</head>';

  const HTML_BODY_OPEN =
    '<body>' +
    '<style>' +
    'html, body {min-width: 100%; font-size: 14px;}' +
    '#zmmtg-root {background-color: #4A4A4A;}' +
    '</style>';

  const HTML_BODY_SCRIPTS =
    '<script src="https://source.zoom.us/3.11.0/lib/vendor/react.min.js">' +
    '</script><script src="https://source.zoom.us/3.11.0/lib/vendor/react-dom.min.js"></script>' +
    '<script src="https://source.zoom.us/3.11.0/lib/vendor/redux.min.js"></script>' +
    '<script src="https://source.zoom.us/3.11.0/lib/vendor/redux-thunk.min.js"></script>' +
    '<script src="https://source.zoom.us/3.11.0/lib/vendor/lodash.min.js"></script>' +
    '<script src="https://source.zoom.us/zoom-meeting-3.11.0.min.js"></script></script>';

  const HTML_BODY_CLOSE = '</body>';

  const cx = useClassNameMapper(localStyles);
  const { authUser } = useContext(AppContext);
  const log = getLog(module);
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.OCCASION_ZOOM_MEETING
  );
  const [meetingStarted, setMeetingStarted] = useState<ZoomMeetingStatus>(
    ZoomMeetingStatus.NotStarted
  );
  const [meetingJoined, setMeetingJoined] = useState<boolean>(false);

  const externalVideoConsentProvided = getExternalVideoConsentCookie();
  const [showBanner, setShowBanner] = useState<boolean>(!externalVideoConsentProvided);
  const tenant = useContext(TenantContext);
  const { isAnonymous } = useRegistrationStatus();

  const queryResult = useQueryWithTracing<
    ZoomMeetingDetailsQuery,
    ZoomMeetingDetailsQueryVariables
  >(module, zoomMeetingDetails, {
    variables: {
      meetingUrl: zoomUrl
    }
  });
  const { data: zoomDetails, loading: zoomDetailsLoading } = queryResult;

  if (zoomDetailsLoading || textLoading) {
    return null;
  }

  const result = zoomDetails?.zoomMeetingDetails?.result;
  const isMeetingStarted = result?.isMeetingStarted;

  if (isMeetingStarted && isMeetingStarted !== meetingStarted) {
    setMeetingStarted(isMeetingStarted as ZoomMeetingStatus);
  }

  /**
   * Re-fetch zoom details query.
   * @param event
   */
  async function reFetchZoomDetails(event: React.MouseEvent): Promise<void> {
    const button = event.target as HTMLButtonElement;
    button.disabled = true;
    await queryResult.refetch();
    button.disabled = false;
    return null;
  }

  /**
   * Reloads the iframe window.
   *
   * @param zoomIframeWindow iframe window to be reloaded.
   */
  function reloadWindow(zoomIframeWindow) {
    zoomIframeWindow.location.reload(true);
    window.location.reload();
  }

  /**
   * This function adds eventlistners to the iframe and window.
   *
   * @param zoomIframeWindow iframe to render zoomsdk.
   */
  function unloadWindowListener(zoomIframeWindow) {
    // when the occasion page browser tab with already joined meeting is refreshed or closed, leave the meeting.
    window.addEventListener('beforeunload', () => {
      zoomIframeWindow?.ZoomMtg?.leaveMeeting({});
    });
    // when an attendee leaves the meeting from the community, refresh the page.
    zoomIframeWindow.addEventListener('unload', () => {
      reloadWindow(zoomIframeWindow);
    });
  }

  /**
   * Function to start zoom meeting.
   *
   * @param zoomIframeWindow iframe to render zoom sdk inside.
   */
  function beginJoin(zoomIframeWindow): void {
    zoomIframeWindow?.ZoomMtg.init({
      leaveUrl: zoomIframeWindow.location.href,
      success: function () {
        unloadWindowListener(zoomIframeWindow);
        zoomIframeWindow?.ZoomMtg.join({
          meetingNumber: result?.meetingNumber,
          userName: isAnonymous ? 'Guest' + Date.now() : authUser.login,
          // adding this condition here because for webinars, userEmail is a mandatory field and we do not want
          // the anonymous users to enter their user email id.
          userEmail:
            result?.meetingType === 'webinar'
              ? isAnonymous
                ? 'Guest' + Date.now() + `@gmail.com`
                : authUser?.email
              : '',
          passWord: result?.passwordRequired ? result?.meetingPassword : '',
          signature: result?.signature,
          sdkKey: result?.sdkKey,
          success: function () {
            unloadWindowListener(zoomIframeWindow);
          },
          error: function (response) {
            log.error('joining meeting failed : ' + response);
            reloadWindow(zoomIframeWindow);
          }
        });
      },
      error: function (response) {
        log.error('Init failed: ' + response);
      }
    });
  }

  /**
   * Function that adds necessary library to ZoomMtg and prepares web sdk.
   *
   * @param zoomIframeWindow iframe to render zoom sdk inside.
   */
  function zoomJoinMeeting(zoomIframeWindow): void {
    zoomIframeWindow?.ZoomMtg.setZoomJSLib('https://source.zoom.us/3.11.0/lib', '/av');
    zoomIframeWindow?.ZoomMtg.preLoadWasm();
    zoomIframeWindow?.ZoomMtg.prepareWebSDK();
    zoomIframeWindow?.ZoomMtg.i18n.load('en-US');
    zoomIframeWindow?.ZoomMtg.i18n.reload('en-US');
    beginJoin(zoomIframeWindow);
  }

  /**
   * Async function that loads zoom web sdk into the iframe in the jsx.
   */
  async function initZoomApp(event: React.MouseEvent<Element>): Promise<void> {
    (event.target as HTMLButtonElement).disabled = true;
    const zoomIframe = document.querySelector(
      '#lia-occasion-zoom-meeting-iframe'
    ) as HTMLIFrameElement;

    zoomIframe.contentDocument.open();
    zoomIframe.contentDocument.write(
      HTML_HEAD + HTML_BODY_OPEN + HTML_BODY_SCRIPTS + HTML_BODY_CLOSE
    );
    zoomIframe.contentDocument.close();

    const zoomIframeWindow = zoomIframe.contentWindow;
    zoomIframe.addEventListener('load', () => {
      setMeetingJoined(true);
      zoomJoinMeeting(zoomIframeWindow);
    });
  }

  /**
   * Function that renders meeting status
   */
  function renderMeetingStatus(): React.ReactElement {
    return meetingStarted === ZoomMeetingStatus.NotStarted && isEventEnded(new Date(endTime)) ? (
      <p className={cx('lia-zoom-meeting-ended')}>{formatMessage('eventEnded')}</p>
    ) : meetingStarted === ZoomMeetingStatus.NotStarted ? (
      <div className={cx('d-flex flex-column')}>
        <Button
          size="lg"
          variant={ButtonVariant.LINK}
          className={cx('lia-zoom-refresh')}
          onClick={event => reFetchZoomDetails(event)}
        >
          {formatMessage('checkMeetingStatus')}
        </Button>
        <p className={cx('lia-zoom-refresh-info')}>{formatMessage('checkMeetingStatusInfo')}</p>
      </div>
    ) : (
      <>
        {tenant.publicConfig.showExternalVideoCookieBanner && !externalVideoConsentProvided ? (
          showBanner && (
            <VideoConsentBanner
              onHide={() => setShowBanner(false)}
              occasionLiveStreamUrl={zoomUrl}
            />
          )
        ) : (
          <Button
            testId="OccasionJoinMeetingButton"
            size="lg"
            variant={ButtonVariant.PRIMARY}
            onClick={event => initZoomApp(event)}
          >
            {formatMessage('joinMeeting')}
          </Button>
        )}
      </>
    );
  }

  return (
    <div className={cx('lia-zoom-root', className)} data-testid="OccasionZoomRoot">
      {!meetingJoined && (
        <div className={cx('lia-zoom-event')}>
          {coverImageUrl && <Image src={coverImageUrl} className={cx('lia-zoom-image')} />}
          <div className={cx('lia-zoom-overlay')}>
            <Icon
              icon={IconTypes.ZoomLogoIcon}
              size={
                variant === OccasionZoomMeetingVariant.LARGE ? IconSize.PX_120 : IconSize.FROM_CSS
              }
              className={cx('lia-zoom-icon')}
            />
            {variant === OccasionZoomMeetingVariant.LARGE && renderMeetingStatus()}
          </div>
        </div>
      )}
      <iframe
        id="lia-occasion-zoom-meeting-iframe"
        title="OccasionZoomIframe"
        allow="camera; microphone"
        className={cx('lia-zoom-iframe', { 'd-none': !meetingJoined })}
      />
    </div>
  );
};
export default OccasionZoomMeeting;
