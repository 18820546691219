import EmptyState from '@aurora/shared-client/components/common/EmptyState/EmptyState';
import { ListVariant } from '@aurora/shared-client/components/common/List/enums';
import { PanelType } from '@aurora/shared-client/components/common/Panel/enums';
import type {
  Entity,
  MessageConstraints,
  MessageSorts
} from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import {
  buildSolutionsListConstraints,
  buildSolutionsListSorts
} from '../../../helpers/messages/MessageHelper/MessageHelper';
import {
  ItemType,
  MessageViewStandardAvatarPosition,
  MessageViewVariant
} from '../../../types/enums';
import type {
  MessageBoardFragment,
  MessageViewFragment,
  MessageViewsQuery,
  MessageViewsQueryVariables
} from '../../../types/graphql-types';
import PaneledItemList from '../../common/List/PaneledItemList/PaneledItemList';
import type { ItemViewTypeAndProps } from '../../entities/types';
import useEntityViewQuery from '../../useEntityViewQuery';
import useTranslation from '../../useTranslation';
import messagesQuery from '../MessageViews.query.graphql';
import type { MessageList } from '../types';

interface Props extends MessageList {
  /**
   * The message to show the list of solutions of.
   */
  message: Entity & MessageBoardFragment;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Whether to hide if empty
   */
  hideIfEmpty?: boolean;
}

/**
 * Display a list of solutions for a message.
 *
 * @constructor
 * @author Dolan Halbrook
 */
const MessageSolutionList: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  className,
  listVariant = {
    type: ListVariant.UNSTYLED,
    props: {
      listItemClassName: (): string => 'lia-g-mt-15'
    }
  },
  viewVariant = {
    type: MessageViewVariant.STANDARD,
    props: {
      avatarPosition: MessageViewStandardAvatarPosition.TOP,
      useAcceptedSolutionButton: true,
      useAvatar: true,
      useReplies: false,
      useBody: true,
      useSearchSnippet: false,
      useKudosButton: false,
      useTags: false,
      useSubject: false,
      useReplyButton: false,
      useAuthorRank: false,
      useNodeLink: false,
      useSolvedBadge: false,
      className: 'lia-g-message-box',
      isSolutionsListMessage: true,
      useCommentButton: false
    }
  },
  pagerVariant,
  pageSize = 10,
  hideIfEmpty = true
}) => {
  const cx = useClassNameMapper();
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.MESSAGE_SOLUTION_LIST
  );
  const constraints: MessageConstraints = buildSolutionsListConstraints(message);
  const sorts: MessageSorts = buildSolutionsListSorts();
  const useEmpty = !hideIfEmpty;

  const variables: MessageViewsQueryVariables = {
    constraints,
    sorts,
    first: pageSize,
    useMedia: true,
    useUserHoverCard: false,
    useNodeHoverCard: false
  };

  const queryResult = useEntityViewQuery<
    ItemType.MESSAGE,
    MessageViewsQuery,
    MessageViewsQueryVariables
  >(module, ItemType.MESSAGE, viewVariant, messagesQuery, {
    variables,
    cacheKey: 'MessageSolutions',
    fetchPolicy: 'network-only'
  });

  if (textLoading) {
    return null;
  }

  /**
   * A callback function to render Empty State when entity list has not message solutions
   */
  function renderNoMessages(): React.ReactElement {
    return <EmptyState description={formatMessage('emptyDescription')} />;
  }

  return (
    <PaneledItemList<
      MessageViewFragment,
      ItemType.MESSAGE,
      ItemViewTypeAndProps<ItemType.MESSAGE, MessageViewVariant>,
      MessageViewsQuery,
      MessageViewsQueryVariables
    >
      type={ItemType.MESSAGE}
      variant={viewVariant}
      queryResult={queryResult}
      listVariant={listVariant}
      pagerVariant={pagerVariant}
      pageSize={pageSize}
      panel={PanelType.NONE}
      className={cx(className)}
      empty={renderNoMessages}
      useEmpty={useEmpty}
    />
  );
};

export default MessageSolutionList;
