import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import ConditionalWrapper from '@aurora/shared-client/components/common/ConditionalWrapper/ConditionalWrapper';
import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import type { UnstyledListTypeAndProps } from '@aurora/shared-client/components/common/List';
import { ListItemSpacing, ListVariant } from '@aurora/shared-client/components/common/List/enums';
import {
  PagerLoadMoreVariant,
  PagerPosition,
  PagerVariant
} from '@aurora/shared-client/components/common/Pager/enums';
import Transition, {
  TransitionVariant
} from '@aurora/shared-client/components/common/Transition/Transition';
import analyticsMutation from '@aurora/shared-client/components/context/AnalyticsParentFrames/Analytics.mutation.graphql';
import type { AppContextInterface } from '@aurora/shared-client/components/context/AppContext/AppContext';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import { ToggleableState } from '@aurora/shared-client/components/form/enums';
import MessageStatusLabel from '@aurora/shared-client/components/messages/MessageStatusLabel/MessageStatusLabel';
import useMessagePolicies from '@aurora/shared-client/components/messages/useMessagePolicies';
import NodeIcon from '@aurora/shared-client/components/nodes/NodeIcon/NodeIcon';
import NodeTitle from '@aurora/shared-client/components/nodes/NodeTitle/NodeTitle';
import useSeoProperties from '@aurora/shared-client/components/seo/useSeoProperties';
import useDevice from '@aurora/shared-client/components/useDevice';
import useMutationWithTracing from '@aurora/shared-client/components/useMutationWithTracing';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import UserAvatar from '@aurora/shared-client/components/users/UserAvatar/UserAvatar';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import UserRank from '@aurora/shared-client/components/users/UserRank/UserRank';
import type { MessageEditingState } from '@aurora/shared-client/helpers/ui/GlobalState';
import useGlobalState, {
  EditorLocation,
  GlobalStateType
} from '@aurora/shared-client/helpers/ui/GlobalState';
import Icons from '@aurora/shared-client/icons';
import type {
  MessagePageOrReplyPageAndParams,
  MessagePagesAndParams,
  MessageReplyPagesAndParams
} from '@aurora/shared-client/routes/endUserRoutes';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type {
  AcceptedSolutionMessage,
  AnalyticsEventsInput,
  Article,
  BlogTopicMessage,
  Discussable,
  Idea,
  IdeaReplyMessage,
  IdeaTopicMessage,
  Message,
  MessageEdge,
  OccasionTopicMessage,
  ReplyMessage,
  Scalars,
  TkbTopicMessage
} from '@aurora/shared-generated/types/graphql-schema-types';
import {
  ContentWorkflowState,
  ConversationStyle,
  CoverImageStyle,
  CoverImageTitlePosition,
  ModerationStatus,
  PostMessageType,
  RepliesFormat,
  VisibilityScope
} from '@aurora/shared-generated/types/graphql-schema-types';
import type {
  AnalyticsMutation,
  AnalyticsMutationVariables
} from '@aurora/shared-generated/types/graphql-types';
import type { ClampLinesType } from '@aurora/shared-types/community/enums';
import { LocalStorageKeys } from '@aurora/shared-types/community/enums';
import { EndUserComponent, EndUserPages } from '@aurora/shared-types/pages/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import UrlBuilder from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlBuilder';
import UrlHelper from '@aurora/shared-utils/helpers/urls/UrlHelper/UrlHelper';
import dynamic from 'next/dynamic';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Accordion, useClassNameMapper } from 'react-bootstrap';
import isEqual from 'react-fast-compare';
import { SwitchTransition } from 'react-transition-group';
import { useLocalStorage } from 'react-use';
import ConversationStyleBehaviorHelper from '../../../../helpers/boards/ConversationStyleBehaviorHelper';
import type { ItemType } from '../../../../types/enums';
import {
  MessageActionMenuItem,
  MessageViewStandardAvatarPosition,
  MessageViewToggleAction,
  MessageViewVariant
} from '../../../../types/enums';
import type {
  AcceptedSolutionButtonFragment,
  IdeaStatusInformationQuery,
  IdeaStatusInformationQueryVariables,
  MessageActionMenuFragment,
  MessageBoardFragment,
  MessageConversationFragment,
  MessageViewFragment,
  NodeLinkFragment,
  ThreadedReplyListSectionFragment
} from '../../../../types/graphql-types';
import AttachmentList from '../../../attachments/AttachmentsList/AttachmentList';
import { UrlObject, useContextObjectRefFromUrl } from '../../../context/useContextObjectFromUrl';
import type { ItemViewVariantFC } from '../../../entities/types';
import GuideBottomNavigation from '../../../guides/GuideBottomNavigation/GuideBottomNavigation';
import ideaStatusPropertiesQuery from '../../../ideas/IdeaStatusInformation.query.graphql';
import NodeLink from '../../../nodes/NodeLink/NodeLink';
import MessageTags from '../../../tags/MessageTags/MessageTags';
import UserLink from '../../../users/UserLink/UserLink';
import useTranslation from '../../../useTranslation';
import AcceptedSolutionButton from '../../AcceptedSolutionButton/AcceptedSolutionButton';
import EscalatedMessageBanner from '../../EscalatedMessageBanner/EscalatedMessageBanner';
import LinearReplyList from '../../LinearReplyList/LinearReplyList';
import MessageAuthorBio from '../../MessageAuthorBio/MessageAuthorBio';
import MessageBody from '../../MessageBody/MessageBody';
import MessageCoverImage from '../../MessageCoverImage/MessageCoverImage';
import MessageCustomFields from '../../MessageCustomFields/MessageCustomFields';
import {
  updatedLocalStateForNewMessageVar,
  updatedMessageForDeepLinkVar
} from '../../MessageDeepLink/MessageReactVarHelper';
import MessageIntroduction from '../../MessageIntroduction/MessageIntroduction';
import MessageLink from '../../MessageLink/MessageLink';
import MessageReplyButton from '../../MessageReplyButton/MessageReplyButton';
import MessageRevision from '../../MessageRevision/MessageRevision';
import MessageSolutionList from '../../MessageSolutionList/MessageSolutionList';
import MessageSolvedBadge from '../../MessageSolvedBadge/MessageSolvedBadge';
import MessageSubject from '../../MessageSubject/MessageSubject';
import MessageTime from '../../MessageTime/MessageTime';
import MessageTimeToRead from '../../MessageTimeToRead/MessageTimeToRead';
import ThreadedReplyList from '../../ThreadedReplyList/ThreadedReplyList';
import ThreadedReplyListSection from '../../ThreadedReplyListSection/ThreadedReplyListSection';
import { useCurrentOrPreviewMessage, useIsPreviewMode } from '../../useCurrentOrPreviewMessage';
import MessageView from '../MessageView';
import type { MessageActionMenuItemAndProps } from '../types';
import localStyles from './MessageViewStandard.module.pcss';
import defaultViewProps from './MessageViewStandardDefaultProps';

const MessageActionMenu = dynamic(() => import('../../MessageActionMenu/MessageActionMenu'), {
  ssr: false
});

const MessageCommentBanner = dynamic(
  () => import('../../MessageCommentBanner/MessageCommentBanner'),
  { ssr: false }
);

const MessageEditorInline = dynamic(() => import('../../MessageEditorInline/MessageEditorInline'), {
  ssr: false
});

const KudosButton = dynamic(() => import('../../../kudos/KudosButton/KudosButton'), { ssr: false });

const OccasionFeaturedGuest = dynamic(
  () => import('../../../occasions/OccasionFeaturedGuest/OccasionFeaturedGuest'),
  { ssr: false }
);

const MessageHelpfulness = dynamic(() => import('../../MessageHelpfulness/MessageHelpfulness'), {
  ssr: false
});

interface MessageViewState {
  /**
   * The message being collapsed or expanded.
   */
  messageId?: Scalars['ID']['output'];
  /**
   * Whether to collapse or expand.
   */
  toggleAction: string;
  /**
   * The message toggle state.
   */
  toggleState: ToggleableState;
}

function getInitialMessageViewState(id, useCollapseActionItem): MessageViewState {
  return {
    messageId: id,
    toggleAction: useCollapseActionItem ? MessageViewToggleAction.COLLAPSE : null,
    toggleState: ToggleableState.OPEN
  };
}

/**
 * Message View - Standard
 *
 * @constructor
 * @author Adam Ayres
 */
const MessageViewStandard: ItemViewVariantFC<
  MessageViewFragment,
  ItemType.MESSAGE,
  MessageViewVariant.STANDARD
> = ({
  entity: publishedMessage,
  variant,
  className,
  useAvatar,
  useBoardIcon,
  useKudosButton,
  useReplyButton,
  useSearchSnippet,
  useAuthorLogin,
  useAuthorRank,
  timeStampType,
  useNodeLink,
  useSubject,
  useHeader = true,
  useRevision = false,
  useCoverImage,
  useTimeToRead,
  useCoverImageTitle,
  useNodeTitle,
  useSubjectLink,
  subjectClassName,
  contentClassName,
  useBody,
  useCustomFields,
  useReplies,
  useSolvedBadge,
  useAcceptedSolutionButton,
  useTags,
  useFooterDivider,
  useAttachments,
  useIntroduction,
  clampBodyLines,
  subjectAs,
  subjectLinkClassName,
  avatarPosition,
  avatarSize,
  kudosProps,
  messageReplyProps,
  actionMenuItems,
  adminActionMenuItems,
  useAccordionForReply,
  usePreview,
  onMessagePost,
  onMessageEdit,
  newlyPostedMessages,
  updateListOnShowMore,
  flattenDepth,
  useUserHoverCard,
  useNodeHoverCard,
  useBio,
  headerClassName,
  useAttachmentPreview,
  useTagsPreview,
  useWideCoverImage,
  usePreviewSubjectModal,
  useModerationStatus = true,
  showNestedRepliesForDeepLinkedComment,
  deepLinkMessageDepth,
  useMessageStatus,
  useVoteComponent,
  useCommentButton,
  useOccasionFeaturedGuest,
  useOccasionAuthor,
  showEscalationBanner,
  showContributorList,
  showTimestamp,
  showVersion,
  useTkbHelpfulnessRating,
  useGuideNavigation,
  repliesFormat,
  isSolutionsListMessage
}) => {
  const cx = useClassNameMapper(localStyles);
  const tenant = useContext(TenantContext);
  const { community } = useContext<AppContextInterface>(AppContext);
  const inViewRefObject = React.useRef<HTMLElement>(null);
  const {
    FormattedMessage,
    formatMessage,
    formatMessageAsNode,
    loading: textLoading
  } = useTranslation(EndUserComponent.MESSAGE_VIEW_STANDARD);
  const accordionReference = useRef(null);
  const [messageEditingState] = useGlobalState(GlobalStateType.MESSAGE_EDITING_STATE);
  const [sessionMessageEditingState, setSessionMessageEditingState] =
    useLocalStorage<MessageEditingState>(LocalStorageKeys.MESSSAGE_SESSION_KEY);
  const [showReplies, setShowReplies] = useState<boolean>(
    showNestedRepliesForDeepLinkedComment ?? false
  );
  const { message: currentMessage } = useCurrentOrPreviewMessage(
    publishedMessage as MessageViewFragment
  );
  const { isMobile } = useDevice();
  const message: MessageViewFragment = (currentMessage as MessageViewFragment) ?? publishedMessage;
  const { id, depth, repliesCount, kudosSumWeight, board, hasGivenKudo, revisionNum } = message;
  const { useAuthorBio } = ConversationStyleBehaviorHelper.getInstance(board);
  const flattenReplyLevel = flattenDepth ?? 7;
  const collapseActionItem = depth !== 0 && repliesCount > 0;
  const [messageViewState, setMessageViewState] = useState<MessageViewState>(
    getInitialMessageViewState(id, collapseActionItem)
  );
  const { toggleState } = messageViewState;
  const isViewed = useRef<boolean>(false);
  const { id: replyId } = useContextObjectRefFromUrl(UrlObject.REPLY);
  const showDeepLinkedComment = replyId !== null;
  const { router } = useEndUserRoutes();
  const { isAnonymous, confirmEmailStatus } = useRegistrationStatus();
  const isPreview = useIsPreviewMode();
  const isMessagePreviewAndDraft =
    isPreview && (message as Article)?.contentWorkflow?.state !== ContentWorkflowState.Publish;
  const {
    seoProperties: { linkCommentToTimeStamp }
  } = community;
  const isPageEditorPage = router?.getCurrentPageName() === EndUserPages.PageEditorPage;
  const { getCaseSensitivePath } = useSeoProperties();
  const isThreadedView = repliesFormat === RepliesFormat.Threaded;

  const { data: policiesData } = useMessagePolicies(
    module,
    {
      id: message.id,
      useCanAcceptSolution: true,
      useCanRejectSolution: true,
      useCanKudo: true,
      useCanEdit: true,
      useCanTag: true,
      useCanReply: true
    },
    !message || IdConverter.isOptimistic(tenant, message?.id)
  );

  // Messages being navigated with guide navigation should fetch latest value (in case rating was added)
  const { data: kbPoliciesData } = useMessagePolicies(
    module,
    {
      id: message.id,
      useCanGiveTkbHelpfulnessRating: useTkbHelpfulnessRating
    },
    isPageEditorPage ||
      (!useTkbHelpfulnessRating && (!message || IdConverter.isOptimistic(tenant, message?.id))),
    false,
    false
  );

  // Analytics call to mark a reply viewed event on replies.
  // This will get triggered only once on the page and only when the reply comes into view.
  const [analytics] = useMutationWithTracing<AnalyticsMutation, AnalyticsMutationVariables>(
    module,
    analyticsMutation
  );

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (
          entry?.intersectionRatio >= 1 &&
          message.depth !== 0 &&
          !isViewed.current &&
          !IdConverter.isOptimistic(tenant, message.id)
        ) {
          const eventsInputObject: AnalyticsEventsInput = {
            domainContext: { target: { id: message.id } },
            messages: [{ target: { id: message.id } }]
          };
          analytics({
            variables: {
              eventsInput: eventsInputObject
            }
          });
          isViewed.current = true;
          observer.disconnect();
        }
      },
      { threshold: 1 }
    );
    if (message.depth !== 0 && inViewRefObject.current) {
      observer.observe(inViewRefObject.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [analytics, message.depth, message.id, tenant]);

  const { parent } = message as ReplyMessage;

  /**
   * Scroll to the parent message on click of the in response to link when the message is reply to a reply.
   * Tries to find the parent element on the DOM by using its uid.
   * If the parent element isn't present in the DOM, then we get rerouted to the parent's permalink page.
   */
  const onClickGoToParentMessage = useCallback(async () => {
    const parentElement = document.querySelector(`#uid${parent?.uid}`)?.closest('article');
    if (parentElement) {
      parentElement.classList.add(cx('lia-scroll-to-parent'));
      parentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      const { messageReplyPage } = ConversationStyleBehaviorHelper.getInstance(message.board);
      const {
        conversation: { topic }
      } = message as MessageConversationFragment;
      const messageReplyParams = {
        boardId: getCaseSensitivePath(message.board.displayId),
        messageSubject: getCaseSensitivePath(UrlHelper.determineSlugForMessagePath(topic)),
        messageId: topic?.uid.toString(),
        replyId: parent?.uid.toString()
      };
      await router.pushRoute<MessageReplyPagesAndParams>(messageReplyPage, messageReplyParams);
    }
  }, [cx, getCaseSensitivePath, message, parent?.uid, router]);

  const toggleAccordion = useCallback(() => {
    if (toggleState === ToggleableState.CLOSED) {
      setMessageViewState({
        messageId: publishedMessage.id,
        toggleAction: MessageViewToggleAction.COLLAPSE,
        toggleState: ToggleableState.OPEN
      });
      accordionReference?.current?.click();
    } else {
      setMessageViewState({
        messageId: publishedMessage.id,
        toggleAction: MessageViewToggleAction.EXPAND,
        toggleState: ToggleableState.CLOSED
      });
      accordionReference?.current?.click();
    }
  }, [publishedMessage.id, toggleState]);

  useEffect(() => {
    if (message.repliesCount === 1) {
      setMessageViewState(previousState => ({
        ...previousState,
        messageId: publishedMessage.id,
        toggleAction: MessageViewToggleAction.COLLAPSE,
        toggleState: ToggleableState.OPEN
      }));
    }
  }, [message.repliesCount, publishedMessage.id]);

  useMemo(() => {
    if (newlyPostedMessages.length > 0 && message.depth === 1) {
      const newList: Message[] = newlyPostedMessages.filter(element => {
        const commonElement = (message as Message).replies?.edges?.find(
          reply => (reply as MessageEdge).node.id === element.id
        );
        if (!commonElement) {
          return element;
        }
        return null;
      });
      if (newList.length !== newlyPostedMessages.length) {
        updateListOnShowMore(newList);
      }
    }
  }, [message, newlyPostedMessages, updateListOnShowMore]);

  const { data: propertiesData, loading: propertiesLoading } = useQueryWithTracing<
    IdeaStatusInformationQuery,
    IdeaStatusInformationQueryVariables
  >(module, ideaStatusPropertiesQuery, {
    variables: {
      id: board.id,
      useIdeaStatusProperties: true
    },
    skip: board.conversationStyle !== ConversationStyle.Idea
  });

  if (textLoading || propertiesLoading) {
    return null;
  }

  const {
    conversation: { solved }
  } = message;

  const isIdeaStatusEnabled = (propertiesData?.coreNode as Idea)?.ideaStatusProperties
    ?.isIdeaStatusEnabled;

  const isPublishedState =
    (message as Article)?.contentWorkflow?.state === ContentWorkflowState.Publish;

  const messagePolicies = policiesData?.message?.messagePolicies;

  const canGiveHelpfulnessRating =
    checkPolicy(
      (kbPoliciesData?.message as TkbTopicMessage)?.tkbMessagePolicies?.canGiveTkbHelpfulnessRating
    ) && isPublishedState;
  const canKudo = messagePolicies?.canKudo;
  const canEdit = checkPolicy(messagePolicies?.canEdit);
  const canTag = checkPolicy(messagePolicies?.canTag);
  const canReply = checkPolicy(messagePolicies?.canReply);
  const canAddOrRemoveTags =
    message.board.conversationStyle === ConversationStyle.Blog ||
    message.board.conversationStyle === ConversationStyle.Tkb
      ? canTag && isPublishedState
      : canTag;

  const isTopic = depth === 0;
  const showTags = tenant.publicConfig.labelsEnabled && useTags && isTopic;
  const coverImageTitlePosition = (message as BlogTopicMessage)?.coverImage
    ? (message as BlogTopicMessage)?.coverImageProperties?.titlePosition
    : CoverImageTitlePosition.Bottom;
  const coverImageStyle =
    (message as BlogTopicMessage)?.coverImage && useWideCoverImage
      ? (message as BlogTopicMessage)?.coverImageProperties?.style
      : CoverImageStyle.Standard;
  const coverImageAltText = (message as BlogTopicMessage)?.coverImage
    ? (message as BlogTopicMessage)?.coverImageProperties?.altText
    : '';
  const isBodyShifted =
    coverImageStyle === CoverImageStyle.Wide &&
    coverImageTitlePosition === CoverImageTitlePosition.Bottom;

  const useShowMoreRepliesButton =
    isThreadedView &&
    (replyId === null
      ? depth !== 0 &&
        depth < flattenReplyLevel + 3 &&
        depth % 3 === 0 &&
        message?.repliesCount > 0 &&
        !showReplies &&
        !usePreviewSubjectModal
      : message?.repliesCount > 0 && !showReplies && (depth - deepLinkMessageDepth) % 3 === 0);

  const items = actionMenuItems?.map(actionMenuItem => actionMenuItem.item) ?? [];
  const adminItems =
    adminActionMenuItems?.map(adminActionMenuItem => adminActionMenuItem.item) ?? [];
  const messageActionToggleMenuitem = {
    item: MessageActionMenuItem.VIEW_TOGGLE,
    props: {
      toggleAccordion,
      currentState: messageViewState.toggleAction
    }
  };

  const isMessageUnmoderated = message?.moderationData?.status === ModerationStatus.Unmoderated;
  const isPreModerateArticle =
    isMessageUnmoderated && message?.visibilityScope === VisibilityScope.PrivateAndUnmoderated;
  const isPostModerateArticle =
    isMessageUnmoderated && message?.visibilityScope === VisibilityScope.Public;

  const updatedLocalStateForNewMessage = updatedLocalStateForNewMessageVar();
  if (updatedLocalStateForNewMessage != null && updatedLocalStateForNewMessage.id === message.id) {
    onMessageEdit(updatedLocalStateForNewMessage);
    updatedLocalStateForNewMessageVar(null);
  }

  /**
   * Returns the modified action menu items based on toggleState and devices.
   */
  function getModifiedActionMenuItems(): MessageActionMenuItemAndProps[] {
    if (useAccordionForReply) {
      if (isMobile && collapseActionItem) {
        if (toggleState === ToggleableState.OPEN) {
          if (!items.includes(MessageActionMenuItem.VIEW_TOGGLE)) {
            return actionMenuItems?.concat([messageActionToggleMenuitem]);
          }
          const viewToggleIndex = actionMenuItems.findIndex(
            item => item.item === MessageActionMenuItem.VIEW_TOGGLE
          );
          return actionMenuItems.splice(viewToggleIndex, 0, messageActionToggleMenuitem);
        }
        return [messageActionToggleMenuitem];
      } else if (toggleState === ToggleableState.CLOSED) {
        return [];
      }
    }
    return actionMenuItems;
  }

  const eventKeyForAccordion = toggleState === ToggleableState.OPEN ? '0' : undefined;

  const localEditState: MessageEditingState = {
    messageId: message.id,
    postMessageType: PostMessageType.Edit,
    editorLocation: EditorLocation.INLINE
  };

  const localReplyState: MessageEditingState = {
    messageId: message.id,
    postMessageType: PostMessageType.Reply,
    editorLocation: EditorLocation.INLINE
  };

  const isEditing =
    adminItems?.includes(MessageActionMenuItem.EDIT_INLINE) &&
    isEqual(localEditState, messageEditingState);
  const isReplying = isEqual(localReplyState, messageEditingState ?? sessionMessageEditingState);

  const isRenderByline =
    avatarPosition === MessageViewStandardAvatarPosition.LEFT &&
    (useAuthorLogin || useAuthorRank || useNodeLink || timeStampType);
  const isRenderInlineActions = useKudosButton;

  const collapseReplyList =
    useAccordionForReply &&
    messageViewState?.toggleState === ToggleableState.CLOSED &&
    id === messageViewState.messageId;

  function renderAvatar(): React.ReactElement {
    return (
      useAvatar && (
        <UserLink user={message.author} className={cx('d-block')} useHoverCard={useUserHoverCard}>
          <UserAvatar user={message.author} size={avatarSize} />
        </UserLink>
      )
    );
  }

  function renderAuthorRank(): React.ReactElement {
    return (
      useAuthorRank && (
        <UserRank className={cx('lia-g-ml-5 lia-author-rank')} user={message.author} />
      )
    );
  }

  function renderInResponseTo(): React.ReactElement {
    const parentAuthor = ((message as ReplyMessage).parent as Message)?.author;
    if (useAuthorLogin && message.depth > 1 && parentAuthor) {
      const parentAuthorLogin = parentAuthor.login || formatMessage('anonymous');

      return (
        <Button
          variant={ButtonVariant.UNSTYLED}
          onClick={onClickGoToParentMessage}
          className={cx('lia-reply-to')}
        >
          {formatMessage('replyToUser', { parentAuthor: parentAuthorLogin })}
        </Button>
      );
    } else {
      return null;
    }
  }

  function renderAuthorLogin(
    textKey = 'authorBy',
    authorLoginClassName: string = null
  ): React.ReactElement {
    const FormattedUserLink = (...chunks): React.ReactNode => (
      <UserLink
        className={cx(authorLoginClassName, 'font-weight-bold small d-flex')}
        user={message.author}
        useHoverCard={useUserHoverCard}
      >
        {chunks}
      </UserLink>
    );
    const messageAuthorLogin = message?.author?.login || formatMessage('anonymous');

    return (
      useAuthorLogin && (
        <span className={cx('lia-g-divider')}>
          <FormattedMessage
            id={textKey}
            values={{
              messageAuthorLogin,
              a: FormattedUserLink
            }}
          />
        </span>
      )
    );
  }

  function renderNodeLink(nodeLinkClassName: string = null): React.ReactElement {
    const FormattedNodeLink = (...chunks): React.ReactNode => (
      <NodeLink
        className={cx(nodeLinkClassName)}
        node={message.board as NodeLinkFragment}
        useHoverCard={useNodeHoverCard}
      >
        {chunks}
      </NodeLink>
    );

    return (
      useNodeLink && (
        <span className={cx('lia-g-divider pl-0 pr-1')}>
          <FormattedMessage
            id="board"
            values={{
              messageBoardTitle: message.board.title,
              // eslint-disable-next-line react/display-name
              a: FormattedNodeLink
            }}
          />
        </span>
      )
    );
  }

  function renderTime(
    as: React.ElementType = 'span',
    postTimeClassName: string = null
  ): React.ReactElement {
    const {
      conversation: {
        topic: { subject }
      }
    } = message;
    return (
      <ConditionalWrapper
        condition={linkCommentToTimeStamp && message.depth != 0 && !showDeepLinkedComment}
        wrapper={props => (
          <MessageLink
            className={cx(postTimeClassName)}
            message={{ ...message, subject }}
            tabIndex={-1}
          >
            {props.children}
          </MessageLink>
        )}
      >
        <MessageTime
          message={message}
          className={cx(postTimeClassName)}
          as={as}
          timestampType={timeStampType}
          usePreferredDateDisplayStyle
        />
      </ConditionalWrapper>
    );
  }

  function renderRepliesCount(as: React.ElementType = 'span'): React.ReactElement {
    const Component = as;
    const replyText =
      message?.repliesCount > 1 ? formatMessage('repliesText') : formatMessage('replyText');
    return (
      message?.repliesCount > 0 &&
      !isTopic &&
      toggleState === ToggleableState.CLOSED && (
        <Component className={cx('lia-g-divider lia-g-text-xs')}>
          {message?.repliesCount} {replyText}
        </Component>
      )
    );
  }

  function renderVoteButton() {
    return (
      <KudosButton
        message={message}
        kudo={hasGivenKudo}
        canKudo={canKudo}
        kudosSum={kudosSumWeight}
        revisionNum={revisionNum}
        messageType={message.__typename}
        view={MessageViewVariant.STANDARD}
      />
    );
  }

  function renderAcceptedSolutionIndicator(
    acceptedSolutionClassName: string = null
  ): React.ReactElement {
    return useAcceptedSolutionButton ? (
      <AcceptedSolutionButton
        message={message as AcceptedSolutionButtonFragment}
        className={cx(acceptedSolutionClassName)}
        onUpdate={onMessageEdit}
      />
    ) : null;
  }

  function renderAvatarPositionLeft(): React.ReactElement {
    return (
      useAvatar &&
      avatarPosition === MessageViewStandardAvatarPosition.LEFT && (
        <section className={cx('lia-g-mr-15')}>{renderAvatar()}</section>
      )
    );
  }

  function renderActionMenu(): React.ReactElement {
    const modifiedActionMenuItems = getModifiedActionMenuItems();
    return (
      modifiedActionMenuItems?.length > 0 && (
        <MessageActionMenu
          items={modifiedActionMenuItems}
          adminItems={adminActionMenuItems}
          message={message as unknown as MessageActionMenuFragment}
          className={cx('ml-auto lia-g-pl-20')}
          iconColor={IconColor.GRAY_600}
          repliesFormat={repliesFormat}
        />
      )
    );
  }

  function renderCoverImageTitle(titleClassName: string): React.ReactElement {
    return (
      useCoverImageTitle && (
        <MessageSubject
          message={message}
          as={subjectAs}
          className={cx(titleClassName, {
            'lia-g-clamp lia-clamp-title':
              coverImageTitlePosition === CoverImageTitlePosition.Overlay
          })}
          useLink={useSubjectLink}
          useTitle
        />
      )
    );
  }

  function renderNodeTitle(): React.ReactElement {
    return (
      useNodeTitle && (
        <NodeTitle
          node={message.board}
          as="div"
          className={cx('lia-g-subheading lia-node-title')}
        />
      )
    );
  }

  function renderTimeToRead(): React.ReactElement {
    return (
      useTimeToRead && (
        <MessageTimeToRead
          message={message}
          as="div"
          className={cx('lia-g-subheading lia-node-title lia-g-ml-25')}
        />
      )
    );
  }

  function renderCoverImage(showImage: boolean): React.ReactElement {
    return (
      useCoverImage &&
      showImage && (
        <MessageCoverImage
          message={message as BlogTopicMessage}
          imageStyle={coverImageStyle}
          titlePosition={coverImageTitlePosition}
          altText={coverImageAltText}
          className={cx(`lia-cover-image-view-${coverImageTitlePosition?.toLowerCase()}`)}
        />
      )
    );
  }

  function renderPostTimeAndReplyCount() {
    return (
      <div className={cx('d-flex flex-row')}>
        {renderTime('span', 'lia-g-divider lia-author-post-time')}
        {variant.props.useRepliesCount && renderRepliesCount('small')}
        {toggleState === ToggleableState.CLOSED && (message as AcceptedSolutionMessage).solution && (
          <span className={cx('lia-g-divider d-flex align-items-center')}>
            <span className={cx('lia-solved-mini')}>{formatMessage('markedAsSolved')}</span>
          </span>
        )}
      </div>
    );
  }

  function renderBadge(badgeClassName: string = null): React.ReactElement {
    return (
      useSolvedBadge && (
        <MessageSolvedBadge
          message={message}
          className={cx(badgeClassName, 'lia-g-ml-25 lia-g-my-5 align-self-start')}
        />
      )
    );
  }

  function renderHeader(): React.ReactElement {
    const headerSectionClassName = cx(headerClassName, 'lia-title-section', {
      'lia-title-section-shift': isBodyShifted
    });

    const headWrapperClassName = cx('lia-head-wrapper', {
      [`lia-head-wrapper-${coverImageStyle?.toLowerCase()}`]:
        coverImageTitlePosition === CoverImageTitlePosition.Overlay
    });

    return (
      avatarPosition === MessageViewStandardAvatarPosition.TOP && (
        <ConditionalWrapper condition={useCoverImage} className={headWrapperClassName}>
          {renderCoverImage(coverImageTitlePosition !== CoverImageTitlePosition.Top)}
          <ConditionalWrapper condition={useCoverImage} className={headerSectionClassName}>
            {(useNodeTitle || useTimeToRead) && (
              <div className={cx('d-flex')}>
                {renderNodeTitle()}
                {renderTimeToRead()}
              </div>
            )}
            {renderCoverImageTitle('lia-cover-image-title')}
            <div
              className={cx('lia-header', {
                'lia-accordion-header': useAccordionForReply
              })}
            >
              <div className={cx('lia-header-main')}>
                {useAvatar && <div className={cx('lia-g-mr-10')}>{renderAvatar()}</div>}
                <div className={cx('lia-header-text')}>
                  <div className={cx('d-flex align-items-center')}>
                    {renderAuthorLogin('author', 'lia-author-text')}
                    {renderAuthorRank()}
                    {renderInResponseTo()}
                    {renderNodeLink()}
                  </div>
                  {useAccordionForReply
                    ? renderPostTimeAndReplyCount()
                    : renderTime('span', 'lia-author-post-time')}
                </div>
                {useVoteComponent && (
                  <div className={cx('d-flex ml-auto')}>{renderVoteButton()}</div>
                )}
              </div>
              {renderBadge()}
              {renderActionMenu()}
            </div>
          </ConditionalWrapper>
          {renderCoverImage(coverImageTitlePosition === CoverImageTitlePosition.Top)}
        </ConditionalWrapper>
      )
    );
  }

  function renderSubject(subjectClass: string = null): React.ReactElement {
    return (
      useSubject && (
        <MessageSubject
          message={message}
          as={subjectAs}
          className={cx(subjectClass)}
          subjectLinkClassName={cx(subjectLinkClassName)}
          useLink={useSubjectLink}
        />
      )
    );
  }

  function renderBoardIcon(): React.ReactElement {
    return (
      useBoardIcon && (
        <section className={cx('lia-g-mr-15')}>
          <NodeIcon node={message.board} size={IconSize.PX_24} />
        </section>
      )
    );
  }

  function renderBody(): React.ReactElement {
    return (
      useBody && (
        <MessageBody
          message={message}
          clampLines={clampBodyLines as ClampLinesType}
          useSearchSnippet={useSearchSnippet}
          className={cx('text-body')}
          usePreview={usePreview}
        />
      )
    );
  }

  function renderCustomFields(): React.ReactElement {
    return useCustomFields && <MessageCustomFields messageId={message.id} />;
  }

  /**
   * Renders the featured guest for occasion.
   */
  function renderOccasionFeaturedGuest(): React.ReactElement {
    return (
      useOccasionFeaturedGuest &&
      depth === 0 && <OccasionFeaturedGuest occasionId={(message as OccasionTopicMessage)?.id} />
    );
  }

  /**
   * Renders the author for occasion.
   */
  function renderOccasionAuthor(): React.ReactElement {
    return (
      useOccasionAuthor && (
        <UserLink
          className={cx('lia-g-divider lia-g-text-xs lia-g-mt-30 text-body')}
          user={message.author}
        >
          {message.author?.login}
        </UserLink>
      )
    );
  }

  function renderRevision(): React.ReactElement {
    const showRevision = useRevision && (showTimestamp || showContributorList || showVersion);
    return (
      showRevision && (
        <MessageRevision
          message={message}
          className={cx('lia-g-text-xs lia-g-divider')}
          showTimestamp={showTimestamp}
          showContributorList={showContributorList}
          showVersion={showVersion}
        />
      )
    );
  }

  function renderIntroduction(): React.ReactElement {
    return (
      useIntroduction && (
        <MessageIntroduction message={message as Article} className={cx('text-body')} />
      )
    );
  }

  function renderByline(): React.ReactElement {
    return (
      isRenderByline && (
        <div className={cx('text-muted')}>
          {renderTime('span', 'lia-g-divider pl-0 pr-1 lia-g-text-xs')}
          {renderAuthorLogin('authorBy', 'lia-g-text-xs')}
          {renderAuthorRank()}
          {renderNodeLink('lia-g-text-xs')}
        </div>
      )
    );
  }

  function renderTags(): React.ReactElement {
    return (
      showTags && (
        <MessageTags target={message} canTag={canAddOrRemoveTags} isTagClickable={useTagsPreview} />
      )
    );
  }

  function renderAttachments(): React.ReactElement {
    return useAttachments ? (
      <AttachmentList
        message={publishedMessage}
        className={cx('lia-attachments', { 'lia-with-divider': useFooterDivider && isTopic })}
        useAttachmentPreview={useAttachmentPreview}
        canEdit={canEdit}
      />
    ) : null;
  }

  function renderInlineActions(): React.ReactElement {
    return (
      isRenderInlineActions && (
        <KudosButton
          message={message}
          kudo={hasGivenKudo}
          canKudo={canKudo}
          kudosSum={kudosSumWeight}
          revisionNum={revisionNum}
          messageType={message.__typename}
          className={cx('lia-g-mr-20 lia-g-ml-40')}
          useText={kudosProps?.useText}
          useCount={kudosProps?.useCount}
        />
      )
    );
  }

  function renderMessageReplyButton(): React.ReactElement {
    return (
      useCommentButton && (
        <MessageReplyButton
          message={message}
          setSessionMessageEditingState={setSessionMessageEditingState}
          useText={messageReplyProps?.useText}
          useCount={messageReplyProps?.useCount}
          canReply={(canReply || isAnonymous || !confirmEmailStatus) && !isMessagePreviewAndDraft}
        />
      )
    );
  }

  function renderSolutions(): React.ReactElement {
    return useAcceptedSolutionButton && isTopic && solved ? (
      <MessageSolutionList message={message} className={cx('lia-g-mt-30')} />
    ) : null;
  }

  function renderTopicAuthorBio(): React.ReactElement {
    return (
      useAuthorBio &&
      depth === 0 &&
      useBio && <MessageAuthorBio message={message as TkbTopicMessage} />
    );
  }

  function renderReplies(): React.ReactElement {
    const leftOffset = useAccordionForReply
      ? cx('lia-accordion-ml-lg')
      : cx('lia-g-ml-md-25 lia-g-ml-10');

    return (
      useReplies &&
      (isThreadedView || showDeepLinkedComment) && (
        <ThreadedReplyListSection
          message={message as unknown as ThreadedReplyListSectionFragment & MessageBoardFragment}
          viewVariant={variant}
          newlyPostedMessageList={newlyPostedMessages}
          updateListOnShowMore={updateListOnShowMore}
          className={cx('lia-g-mt-5', {
            'd-none': collapseReplyList
          })}
          pagerVariant={{
            type: PagerVariant.LOAD_MORE,
            props: {
              position: PagerPosition.START,
              clearLoadingAfterUpdate: true,
              variant: PagerLoadMoreVariant.BORDERED,
              className: leftOffset
            }
          }}
        />
      )
    );
  }

  function renderFooter(): React.ReactElement {
    return (
      (isRenderByline ||
        isRenderInlineActions ||
        useTags ||
        useCommentButton ||
        useAcceptedSolutionButton) && (
        <div
          className={cx('lia-footer', {
            'lia-footer-divider': useFooterDivider && isTopic
          })}
        >
          {(isRenderByline || useAcceptedSolutionButton || showTags) && (
            <div
              className={cx('lia-footer-left', {
                'lia-has-tags': showTags
              })}
            >
              {renderByline()}
              {message.depth > 0 && renderAcceptedSolutionIndicator()}
              {renderTags()}
            </div>
          )}
          {
            <div className={cx('lia-footer-right')}>
              {renderInlineActions()}
              {renderMessageReplyButton()}
            </div>
          }
        </div>
      )
    );
  }

  function renderEditorForEdit(): React.ReactElement {
    return (
      <MessageEditorInline
        message={message}
        onSuccess={newMessage => {
          updatedMessageForDeepLinkVar(newMessage as Message);
          if (onMessageEdit) {
            onMessageEdit(newMessage as Message);
          }
        }}
        postMessageType={PostMessageType.Edit}
        wrapperClassName={cx('lia-g-mt-10 lia-g-mb-40', {
          'lia-g-ml-md-20 lia-g-ml-10': useAccordionForReply
        })}
      />
    );
  }

  function renderEditorForReply(): React.ReactElement {
    return (
      useReplyButton && (
        <Transition in={isReplying} unmountOnExit variant={TransitionVariant.ZOOM_FADE}>
          <MessageEditorInline
            message={message}
            onSuccess={newMessage => {
              updatedMessageForDeepLinkVar(newMessage as Message);
              setSessionMessageEditingState(null);
              if (onMessagePost) {
                onMessagePost(newMessage as Message);
              }
            }}
            onSuccessOptimistic={() => {
              if (message.depth % 3 === 0 && message.depth !== 0) {
                const isParentMessageNewlyPosted = newlyPostedMessages.includes(message as Message);
                if (!showReplies) {
                  setShowReplies(isParentMessageNewlyPosted || message.repliesCount === 0);
                }
              }
            }}
            onCancel={() => setSessionMessageEditingState(null)}
            postMessageType={PostMessageType.Reply}
            wrapperClassName={cx('lia-g-mt-10 lia-g-mb-40', {
              'lia-accordion-ml-sm': useAccordionForReply
            })}
          />
        </Transition>
      )
    );
  }

  const messageViewVariant = {
    type: MessageViewVariant.STANDARD,
    props: {
      ...defaultViewProps,
      avatarPosition: MessageViewStandardAvatarPosition.TOP,
      useReplies: true,
      useRepliesCount: true,
      useSubject: false,
      useNodeLink: false,
      useKudosCount: true,
      useAcceptedSolutionButton: useAcceptedSolutionButton,
      useTags: true,
      useContributors: true,
      useSolvedBadge: false,
      actionMenuItems: [
        { item: MessageActionMenuItem.REPORT_ABUSE },
        { item: MessageActionMenuItem.MARK_AS_APPROVED },
        { item: MessageActionMenuItem.REJECT },
        { item: MessageActionMenuItem.MARK_AS_NOT_SPAM }
      ],
      adminActionMenuItems: [
        { item: MessageActionMenuItem.EDIT_INLINE },
        { item: MessageActionMenuItem.DELETE },
        { item: MessageActionMenuItem.COPY_LINK },
        { item: MessageActionMenuItem.MOVE_COMMENT },
        { item: MessageActionMenuItem.SUBSCRIBE }
      ],
      useAccordionForReply,
      className: 'lia-g-message-box',
      flattenDepth: 7,
      useReadOnlyIcon: true,
      deepLinkMessageDepth,
      repliesFormat
    }
  };

  const messageListVariant: UnstyledListTypeAndProps<Message> = {
    type: ListVariant.UNSTYLED,
    props: {
      listItemClassName: (): string =>
        message.depth < flattenReplyLevel ? 'lia-g-ml-md-25 lia-g-ml-15' : '',
      listItemSpacing: ListItemSpacing.SM
    }
  };

  const messagePagerVariant = {
    type: PagerVariant.LOAD_MORE,
    props: {
      position: PagerPosition.START,
      clearLoadingAfterUpdate: true,
      variant: PagerLoadMoreVariant.BORDERED,
      className: cx(
        'lia-g-mb-10 lia-g-pt-5',
        useAccordionForReply ? 'lia-accordion-ml-sm' : 'lia-g-ml-md-25 lia-g-ml-10'
      )
    }
  };

  function renderShowMoreReplies() {
    return (
      showReplies &&
      (isThreadedView || showDeepLinkedComment) && (
        <div
          className={cx('lia-g-mt-5', {
            'd-none': collapseReplyList
          })}
        >
          {depth < flattenReplyLevel ? (
            <ThreadedReplyList
              message={message}
              viewVariant={messageViewVariant}
              listVariant={messageListVariant}
              pagerVariant={messagePagerVariant}
              useHeader={false}
              showNestedRepliesForDeepLinkedComment={showDeepLinkedComment}
            />
          ) : (
            <LinearReplyList
              message={message}
              viewVariant={messageViewVariant}
              listVariant={messageListVariant}
              pagerVariant={messagePagerVariant}
              useHeader={false}
            />
          )}
        </div>
      )
    );
  }

  function renderShowMoreRepliesButton() {
    return (
      useShowMoreRepliesButton && (
        <div
          className={cx(
            'border-bottom lia-g-mb-10',
            useAccordionForReply ? 'lia-accordion-ml-lg' : 'lia-g-ml-md-25 lia-g-ml-15'
          )}
        >
          <Button
            variant={ButtonVariant.LINK}
            loading={showReplies}
            className={cx('lia-g-loader-btn lia-g-pt-10 lia-g-pb-5', {
              'd-none': collapseReplyList
            })}
            onClick={() => setShowReplies(true)}
          >
            <Icon
              icon={Icons.ChevronDownIcon}
              size={IconSize.PX_16}
              color={IconColor.LOAD_TEXT}
              className={cx('lia-g-mr-5')}
            />
            {formatMessage('showMoreReplies')}
          </Button>
        </div>
      )
    );
  }

  /**
   * Renders the status of the message
   */
  function renderMessageStatus() {
    const messageStatus = (message as IdeaTopicMessage).status;
    return (
      message.board.conversationStyle === ConversationStyle.Idea &&
      messageStatus && (
        <div className={cx('lia-message-status')}>
          {formatMessage('messageStatus')}
          <MessageStatusLabel status={messageStatus} />
        </div>
      )
    );
  }

  const previousStatus = (message as IdeaReplyMessage)?.status?.previousStatus;
  const currentStatus = (message as IdeaReplyMessage)?.status?.currentStatus;
  const isStatusChangeComment =
    message.depth !== 0 &&
    board.conversationStyle === ConversationStyle.Idea &&
    (currentStatus !== null || previousStatus !== null);
  const isPreviousStatusVisible = previousStatus?.label.visible;
  const isCurrentStatusVisible = currentStatus?.label.visible;

  /**
   * Function that renders the status change information in the idea reply message
   */
  function renderChangeStatusText(): React.ReactElement {
    if (
      isIdeaStatusEnabled &&
      isStatusChangeComment &&
      (isPreviousStatusVisible || isCurrentStatusVisible)
    ) {
      let text;
      if (!isPreviousStatusVisible) {
        text = formatMessageAsNode('statusAdded', {
          status: <MessageStatusLabel status={currentStatus} />
        });
      } else if (!isCurrentStatusVisible) {
        text = formatMessageAsNode('statusRemoved', {
          status: <MessageStatusLabel status={previousStatus} />
        });
      } else {
        text = formatMessageAsNode('statusChanged', {
          previousStatus: <MessageStatusLabel status={previousStatus} />,
          currentStatus: <MessageStatusLabel status={currentStatus} />
        });
      }
      return (
        <div className={cx('lia-status-change-text lia-g-pt-5', { 'lia-g-pb-30': message.body })}>
          {text}
        </div>
      );
    }
  }

  function renderMessageContent() {
    return (
      <ConditionalWrapper condition={!!contentClassName} className={cx(contentClassName)}>
        {renderIntroduction()}
        {renderChangeStatusText()}
        {renderBody()}
        {renderCustomFields()}
        {renderOccasionFeaturedGuest()}
        {(useOccasionAuthor || useRevision) && (
          <div className={cx('d-flex align-items-center')}>
            {renderOccasionAuthor()}
            {renderRevision()}
          </div>
        )}
        {renderAttachments()}
        {renderFooter()}
        {renderSolutions()}
        {renderTopicAuthorBio()}
      </ConditionalWrapper>
    );
  }

  function wrapMessageContentInAccordionCollapse() {
    return (
      <Accordion.Collapse eventKey={eventKeyForAccordion} className={cx('lia-g-mt-10')}>
        {renderMessageContent()}
      </Accordion.Collapse>
    );
  }

  function recordMessageReference(item): void {
    inViewRefObject.current = item;
  }

  const useCommentBanner =
    depth !== 0 &&
    useModerationStatus &&
    (isPreModerateArticle || (isPostModerateArticle && showDeepLinkedComment));

  function renderMessageCommon() {
    return (
      <div className={cx('lia-wrap')}>
        {useCommentBanner && (
          <MessageCommentBanner message={message} isPreModArticle={isPreModerateArticle} />
        )}
        <article
          ref={ref => recordMessageReference(ref)}
          className={cx(
            'lia-article',
            {
              'lia-g-solved-border lia-g-pl-20': (message as AcceptedSolutionMessage).solution
            },
            { 'lia-solved-topic-background': isSolutionsListMessage },
            { 'lia-has-comment-banner': useCommentBanner },
            className
          )}
          data-testid="StandardMessageView"
        >
          {renderAvatarPositionLeft()}
          {renderBoardIcon()}
          <section
            className={cx('lia-section')}
            id={isSolutionsListMessage ? null : `uid${message.uid}`}
          >
            <div className={cx('d-flex', 'flex-column')}>
              {useHeader && renderHeader()}
              {useMessageStatus && renderMessageStatus()}
              {useSubject && (
                <div
                  className={cx('d-flex flex-row justify-content-between align-items-md-center')}
                >
                  {renderSubject(subjectClassName)}
                </div>
              )}
            </div>
            {useAccordionForReply
              ? wrapMessageContentInAccordionCollapse()
              : renderMessageContent()}
          </section>
          {((isPageEditorPage && useTkbHelpfulnessRating) || canGiveHelpfulnessRating) && (
            <MessageHelpfulness
              key={message.id}
              message={message}
              unhelpfulReasons={[
                formatMessage('unhelpfulReason.reason1'),
                formatMessage('unhelpfulReason.reason2'),
                formatMessage('unhelpfulReason.reason3'),
                formatMessage('unhelpfulReason.reason4')
              ]}
            />
          )}
        </article>
        {useGuideNavigation && (
          <GuideBottomNavigation className={cx('lia-article-guide-navigation')} />
        )}
      </div>
    );
  }

  function renderAccordionWrappedMessage(): React.ReactElement {
    return (
      <>
        <Accordion
          defaultActiveKey={eventKeyForAccordion}
          className={cx('lia-accordion')}
          activeKey={eventKeyForAccordion}
        >
          <Accordion.Toggle
            data-testid="Button"
            aria-label={
              toggleState === ToggleableState.CLOSED
                ? formatMessage('labelExpand')
                : formatMessage('labelCollapse')
            }
            eventKey={eventKeyForAccordion}
            onClick={toggleAccordion}
            ref={accordionReference}
            className={cx('lia-accordion-toggle lia-g-icon-btn', {
              'lia-accordion-is-closed': toggleState === ToggleableState.CLOSED
            })}
            aria-expanded={toggleState === ToggleableState.CLOSED ? false : true}
            aria-controls={`uid${message.uid}`}
          >
            <Icon
              className={cx('lia-accordion-icon')}
              icon={Icons.ChevronRightIcon}
              color={IconColor.GRAY_900}
              size={IconSize.PX_16}
            />
          </Accordion.Toggle>
          {renderMessageCommon()}
        </Accordion>
      </>
    );
  }

  /**
   * Renders an optional placeholder for moved messages with a url pointing to the new location
   */
  function renderPlaceholder(): React.ReactElement {
    let relativeUrlForRoute: string;
    const { messagePage } = ConversationStyleBehaviorHelper.getInstance(message.board);
    if ((message.originalMessageForPlaceholder as ReplyMessage)?.parent) {
      const originalMessageParent = (message.originalMessageForPlaceholder as ReplyMessage)
        .parent as Discussable;
      relativeUrlForRoute = router.getRelativeUrlForRoute<MessagePagesAndParams>(messagePage, {
        boardId: message.board.displayId,
        messageSubject: UrlHelper.determineSlugForMessagePath(originalMessageParent),
        messageId: originalMessageParent.uid.toString()
      });
    } else {
      const originalMessage = message.originalMessageForPlaceholder as Discussable;
      relativeUrlForRoute = router.getRelativeUrlForRoute<MessagePageOrReplyPageAndParams>(
        messagePage,
        {
          boardId: message.board.displayId,
          messageSubject: UrlHelper.determineSlugForMessagePath(originalMessage),
          messageId: originalMessage.uid.toString()
        }
      );
    }
    const movedMessageUrl = UrlBuilder.fromUrl(tenant.baseUrl).addPath(relativeUrlForRoute).build();

    return (
      <div className={cx(className, { 'lia-g-ml-25': isThreadedView })}>
        <Icon
          icon={Icons.ArrowRightIcon}
          size={IconSize.PX_16}
          color={IconColor.GRAY_700}
          className={cx('lia-arrow-icon')}
        />
        <span className={cx('lia-moved')}>
          {formatMessage(`movedMessagePlaceholder.${message.board.conversationStyle}`, {
            count: message.originalMessageForPlaceholder.repliesCount
          })}
          <a href={movedMessageUrl}> {formatMessage('movedMessagePlaceholderUrlText')}</a>
        </span>
      </div>
    );
  }

  function renderMessage(): React.ReactElement {
    return message.placeholder && message.originalMessageForPlaceholder
      ? renderPlaceholder()
      : useAccordionForReply
      ? renderAccordionWrappedMessage()
      : renderMessageCommon();
  }

  /**
   * Render newly posted reply.
   */
  function renderNewlyPostedReplyList() {
    return newlyPostedMessages.map(postedMessage => {
      if ((postedMessage as ReplyMessage).parent.id === message.id) {
        return (
          <div
            key={`${postedMessage.id}-${postedMessage.revisionNum}`}
            className={cx('lia-g-mt-5', {
              'lia-g-ml-md-25 lia-g-ml-15': depth < flattenReplyLevel && isThreadedView
            })}
          >
            <MessageView entity={postedMessage} variant={variant} />
          </div>
        );
      }
      return null;
    });
  }

  return (
    <>
      {showEscalationBanner && (
        <EscalatedMessageBanner
          message={message}
          className={cx(
            depth !== 0 && useAccordionForReply ? 'lia-escalate-reply-banner' : 'lia-g-pl-0'
          )}
        />
      )}
      <SwitchTransition>
        <Transition variant={TransitionVariant.ZOOM_FADE} key={`${isEditing}`}>
          {isEditing && !isAnonymous && confirmEmailStatus
            ? renderEditorForEdit()
            : renderMessage()}
        </Transition>
      </SwitchTransition>
      {!isAnonymous && confirmEmailStatus && renderEditorForReply()}
      {!collapseReplyList && (
        <>
          {newlyPostedMessages.length > 0 && renderNewlyPostedReplyList()}
          {renderReplies()}
        </>
      )}
      {renderShowMoreRepliesButton()}
      {renderShowMoreReplies()}
    </>
  );
};

export default MessageViewStandard;
