import type { ApolloQueryResult } from '@apollo/client';
import EmptyState from '@aurora/shared-client/components/common/EmptyState/EmptyState';
import { IconColor, IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import ListTitle from '@aurora/shared-client/components/common/List/ListTitle';
import {
  PagerLoadMoreVariant,
  PagerPosition,
  PagerVariant
} from '@aurora/shared-client/components/common/Pager/enums';
import Pager from '@aurora/shared-client/components/common/Pager/Pager';
import usePaginationCursor from '@aurora/shared-client/components/common/Pager/usePaginationCursor';
import { PanelType } from '@aurora/shared-client/components/common/Panel/enums';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import PaginationHelper, {
  PaginationDirection
} from '@aurora/shared-client/helpers/ui/PaginationHelper/PaginationHelper';
import Icons from '@aurora/shared-client/icons';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import type {
  Connection,
  Message,
  ReplyMessage
} from '@aurora/shared-generated/types/graphql-schema-types';
import { ConversationStyle } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import { getLog } from '@aurora/shared-utils/log';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import useGlobalState, {
  EditorLocation,
  GlobalStateType
} from '@aurora/shared-client/helpers/ui/GlobalState';
import {
  ApolloQueryCacheKey,
  AriaLiveRegions,
  ItemType,
  MessageViewVariant
} from '../../../types/enums';
import type {
  MessageAuthorFragment,
  MessageBoardFragment,
  MessageReadOnlyIconFragment,
  MessageRepliesQuery,
  MessageRepliesQueryVariables,
  MessageViewFragment,
  ThreadedReplyListFragment
} from '../../../types/graphql-types';
import PaneledItemList from '../../common/List/PaneledItemList/PaneledItemList';
import type { ItemViewTypeAndProps } from '../../entities/types';
import useEntityViewQuery from '../../useEntityViewQuery';
import useTranslation from '../../useTranslation';
import type { MessageListMenuItemDefinition } from '../MessageListMenu/MessageListMenu';
import MessageListMenu, {
  MessageListMenuItem,
  messageListMenuItemMap
} from '../MessageListMenu/MessageListMenu';
import messageRepliesQuery from '../MessageReplies.query.graphql';
import mergeVariantProps from '../MessageView/MessageViewDefaultProps';
import type { MessageReplyProps, MessageViewStandardProps } from '../MessageView/types';
import type { MessageList } from '../types';
import useMessageSort from '../useMessageSort';
import localStyles from './ThreadedReplyList.module.css';
import { useApolloClient } from '@apollo/client';
import dynamic from 'next/dynamic';

const ThreadSummary = dynamic(() => import('../ThreadSummary/ThreadSummary'), { ssr: false });

const log = getLog(module);

interface MessageReplyListProps extends MessageList {
  /**
   * The message to display a list of threaded replies for.
   */
  message: ThreadedReplyListFragment &
    MessageBoardFragment &
    MessageAuthorFragment &
    MessageReadOnlyIconFragment;
  /**
   * Whether to use the header for the paneled item list or not.
   */
  useHeader?: boolean;
  /**
   * Whether to use the empty state if there are no reply messages.
   */
  useEmpty?: boolean;

  /**
   * holds the reference of the Message editor.
   */
  editorRef?: React.MutableRefObject<HTMLElement>;
  /**
   * Show the nested replies for a deep-linked comment
   */
  showNestedRepliesForDeepLinkedComment?: boolean;
}

/**
 * Display a list of replies for a message. The message is not expected to have the
 * replies field already populated and the query to get the replies will be executed
 * as part of the rendering of this component. If you need to render a list of replies
 * for a message where the replies are already populated then use `ThreadedReplyListSection`.
 *
 * This component will also display a title above the replies list, allow for sorting, and show
 * an empty message when no replies are present.
 *
 * @constructor
 * @author Adam Ayres
 */
const ThreadedReplyList: React.FC<React.PropsWithChildren<MessageReplyListProps>> = ({
  message,
  pageSize,
  viewVariant = {
    type: MessageViewVariant.STANDARD,
    props: {
      useAccordionForReply: false
    }
  },
  listVariant,
  pagerVariant = {
    type: PagerVariant.LOAD_MORE,
    props: {
      position: PagerPosition.START,
      variant: PagerLoadMoreVariant.BORDERED
    }
  },
  useHeader = true,
  useEmpty = false,
  editorRef,
  showNestedRepliesForDeepLinkedComment = false
}) => {
  const { authUser, contextUser } = useContext(AppContext);
  const user = contextUser ?? authUser;
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.THREADED_REPLY_LIST
  );
  const { conversationStyle } = message.board;
  const isIdea = conversationStyle === ConversationStyle.Idea;
  const cx = useClassNameMapper(localStyles);
  const tenant = useContext(TenantContext);
  const [reloading, setReloading] = useState(false);
  const [newMessages, setNewMessages] = useState<Message[]>([]);
  const [messageDeletingState] = useGlobalState(GlobalStateType.MESSAGE_DELETING_STATE);

  const viewVariantProps = mergeVariantProps<Record<string, MessageReplyProps>>(viewVariant);
  const topicRepliesFirst =
    pageSize ?? viewVariantProps?.messageReplyProps?.topicRepliesCount ?? 10;
  const nestedRepliesFirst = viewVariantProps?.messageReplyProps?.nestedRepliesCount ?? 3;
  const isTopic = message.depth === 0;
  const [messageEditingState] = useGlobalState(GlobalStateType.MESSAGE_EDITING_STATE);
  const editorLocation = messageEditingState?.editorLocation;

  const afterCursorParam = usePaginationCursor();
  const beforeCursorParam = usePaginationCursor(EndUserQueryParams.BEFORE);
  const repliesSortParam = usePaginationCursor(EndUserQueryParams.SORT_MENU_QUERY_PARAMETER);
  const messageSortParam = usePaginationCursor(
    EndUserQueryParams.MESSAGE_LIST_MENU_QUERY_PARAMETER
  );
  const client = useApolloClient();

  const repliesFirst =
    message.depth % 3 === 0 || showNestedRepliesForDeepLinkedComment
      ? topicRepliesFirst
      : nestedRepliesFirst;

  const { sortMenuItem, loading: repliesSortOrderLoading } = useMessageSort();

  const { sorts } = messageListMenuItemMap[sortMenuItem];

  const queryParams = {
    ...(messageSortParam && {
      [EndUserQueryParams.MESSAGE_LIST_MENU_QUERY_PARAMETER]: messageSortParam
    }),
    ...(repliesSortParam && { [EndUserQueryParams.SORT_MENU_QUERY_PARAMETER]: repliesSortParam })
  };

  const variables = useMemo(() => {
    return {
      id: message?.id,
      sorts,
      repliesSorts: sorts,
      repliesFirst: nestedRepliesFirst,
      repliesFirstDepthThree: 1,
      useKudosCount: true,
      useTags: viewVariant?.props?.useTags && isTopic,
      useContributors: false,
      useReadOnlyIcon: false,
      attachmentsFirst: 5,
      useRepliesCount: true,
      ...(isIdea && {
        constraints: {
          includeHiddenComments: {
            eq: false
          }
        }
      }),
      ...(!isTopic && { first: repliesFirst }),
      ...(isTopic && !afterCursorParam && !beforeCursorParam && { first: topicRepliesFirst }),
      ...(isTopic && afterCursorParam && { after: afterCursorParam, first: topicRepliesFirst }),
      ...(isTopic &&
        beforeCursorParam &&
        !afterCursorParam && { before: beforeCursorParam, last: topicRepliesFirst })
    };
  }, [
    isTopic,
    message?.id,
    nestedRepliesFirst,
    sorts,
    viewVariant?.props?.useTags,
    afterCursorParam,
    beforeCursorParam,
    repliesFirst,
    topicRepliesFirst,
    isIdea
  ]);

  const cacheKey =
    (isTopic ? ApolloQueryCacheKey.TOPIC_REPLY_LIST : ApolloQueryCacheKey.REPLY_LIST) +
    `:${message.id}:${message.revisionNum}`;
  const queryResult = useEntityViewQuery<
    ItemType.MESSAGE,
    MessageRepliesQuery,
    MessageRepliesQueryVariables
  >(module, ItemType.MESSAGE, viewVariant, messageRepliesQuery, {
    variables: {
      ...variables,
      useAttachments: false,
      useMedia: false,
      useModerationStatus: true,
      useNodeAncestors: false,
      useNodeHoverCard: false,
      useMessageStatus: true,
      useUserHoverCard: false
    },
    skip: IdConverter.isOptimistic(tenant, message?.id),
    cacheKey,
    onCompleted: () => {
      if (editorLocation === EditorLocation.CTA) {
        editorRef?.current
          ?.getElementsByClassName('lia-reply-cta-bottom')
          .item(0)
          .scrollIntoView(false);
      }
    }
  });

  const { threadSummaryLimit } = tenant.publicConfig;
  const useThreadSummary =
    !queryResult.loading &&
    threadSummaryLimit > -1 &&
    queryResult.data?.message?.repliesCount >= threadSummaryLimit;

  const useAccordionForReply =
    viewVariant.type === MessageViewVariant.STANDARD &&
    (viewVariant.props as MessageViewStandardProps).useAccordionForReply;

  const threadedPagerVariant = {
    type: isTopic ? PagerVariant.LOAD_MORE_PREVIOUS_NEXT_LINKABLE : pagerVariant.type,
    props: {
      className: cx('lia-pager', { 'lia-g-ml-md-25': useAccordionForReply }),
      ...pagerVariant.props,
      queryParams
    }
  };
  const { refetch } = queryResult;
  const { router } = useEndUserRoutes();
  const autoScroll = router.getUnwrappedQueryParam(EndUserQueryParams.AUTO_SCROLL);

  const sortMenuValue = router.getQueryParam(
    EndUserQueryParams.SORT_MENU_QUERY_PARAMETER,
    null
  ) as string;
  const messageType = (message as MessageViewFragment).__typename;

  /**
   * Evicts the cached replies when the user clicks on 'show more' for Messages with Depth 3 or any multiple of 3
   */
  useEffect(() => {
    if (sortMenuValue && message.depth % 3 === 0) {
      client.cache.evict({ id: `${messageType}:${message.id}`, fieldName: 'replies' });
      client.cache.gc();
    }
  }, [sortMenuValue, client.cache, message.depth, message.id, messageType]);

  useEffect(() => {
    if (!messageDeletingState || newMessages.length === 0) {
      return;
    }

    const deletedMessageFound = newMessages.find(
      element => element.id === messageDeletingState.messageId
    );

    if (!deletedMessageFound) {
      return;
    }

    setNewMessages(previousState =>
      previousState.filter(
        (localMessage: ReplyMessage) =>
          localMessage.id !== messageDeletingState.messageId &&
          !localMessage.ancestors.edges.some(
            ({ node }: { node: Message }) => node.id === messageDeletingState.parentId
          )
      )
    );
  }, [messageDeletingState, newMessages]);

  function onMessagePost(newMessage): void {
    let messageHolder: ReplyMessage = newMessage;
    setNewMessages(previousState => {
      let ifNewMessagePushed = false;
      const tempList = [];
      const updateNewMessageInList = () => {
        if (ifNewMessagePushed === false) {
          tempList.push(newMessage);
          ifNewMessagePushed = true;
        }
      };
      if (previousState.length > 0) {
        previousState?.forEach((localMessage, index) => {
          // If multiple messages with same parent, update it in desc order of posted time.
          if (newMessage.parent.id === (localMessage as ReplyMessage).parent.id) {
            updateNewMessageInList();
            tempList.push(localMessage);
            // If parent message is in list, then update parent message and then child.
            // OR if reached at the last element of the array and message is not yet updated in list.
          } else if (
            newMessage.parent.id === localMessage.id ||
            (index === previousState.length - 1 && ifNewMessagePushed === false)
          ) {
            tempList.push(localMessage);
            updateNewMessageInList();
          } else {
            tempList.push(localMessage);
          }
        });
      } else {
        tempList.push(newMessage);
      }
      if (tempList.length > 1) {
        const updatedRepliesCountList = tempList.reverse().map(message_ => {
          if (messageHolder.parent.id === message_.id) {
            const updatedMessage = {
              ...message_,
              repliesCount: message_.repliesCount ? message_.repliesCount + 1 : 1
            };
            messageHolder = updatedMessage;
            return updatedMessage;
          } else {
            return message_;
          }
        });
        return updatedRepliesCountList.reverse();
      }
      return tempList;
    });
  }

  const [viewVariantState, setViewVariantState] = useState({
    ...viewVariant,
    props: {
      onMessagePost,
      onMessageEdit: newMessage => {
        const updatedMessage: Message = newMessage;
        setNewMessages(previousState => {
          return previousState.map(replyMessage => {
            if (replyMessage.id === updatedMessage.id) {
              return {
                ...replyMessage,
                ...updatedMessage,
                author: replyMessage.author,
                conversation: updatedMessage.conversation
              };
            }
            if (replyMessage.conversation.solved !== updatedMessage.conversation.solved) {
              return {
                ...replyMessage,
                conversation: updatedMessage.conversation
              };
            }
            return replyMessage;
          });
        });
      },
      updateListOnShowMore: newList => {
        setNewMessages(newList);
      },
      newlyPostedMessages: newMessages,
      ...viewVariant.props
    }
  });
  const replyHeaderRef = useRef<HTMLDivElement>(null);
  /**
   * Used for scrolling to Reply section when autoScroll=true and topicRepliesSort=postTimeDesc
   */
  useEffect(() => {
    if (autoScroll) {
      setTimeout(() => {
        const replyHeaderElement = replyHeaderRef.current as HTMLElement;
        replyHeaderElement?.scrollIntoView({ behavior: 'smooth' });
        router.removeQueryParam([EndUserQueryParams.AUTO_SCROLL]);
      }, 2000);
    }
  });

  useEffect(() => {
    setViewVariantState(previousState => ({
      type: previousState.type,
      props: {
        ...previousState.props,
        newlyPostedMessages: newMessages
      }
    }));
  }, [newMessages]);

  const onUpdate = useCallback(
    (connection: Connection): MessageRepliesQuery => {
      return {
        message: {
          id: message.id,
          revisionNum: message.revisionNum,
          __typename: message.__typename,
          replies: connection,
          parent: (message as ReplyMessage).parent ?? {},
          repliesCount: message.repliesCount
        }
      } as unknown as MessageRepliesQuery;
    },
    [message]
  );

  const renderNoReplies = useCallback((): React.ReactElement => {
    return (
      !message.readOnly && (
        <EmptyState
          icon={Icons.RepliesIcon}
          description={
            message.author.login !== user?.login ? formatMessage('noRepliesDescription') : null
          }
          title={formatMessage('noRepliesTitle')}
        />
      )
    );
  }, [formatMessage, message.author.login, user?.login, message.readOnly]);

  const handleListMenuChange = useCallback(
    (
      item: MessageListMenuItemDefinition
    ): Promise<void | ApolloQueryResult<MessageRepliesQuery>> => {
      setNewMessages([]);
      setReloading(true);
      return refetch({
        ...variables,
        after: null,
        before: null,
        sorts: item.sorts,
        repliesSorts: item.sorts
      })
        .finally(() => {
          setReloading(false);
        })
        .catch(error => log.error(error, 'Error loading replies for topic'));
    },
    [refetch, variables]
  );

  /**
   * Render the header with replies count and message menu for reply list.
   */
  const renderHeader = useCallback(
    (): React.ReactElement => {
      const messageListMenuItems: MessageListMenuItem[] = [
        MessageListMenuItem.KUDOS_SUM_WEIGHT_DESC,
        MessageListMenuItem.POST_TIME_ASC,
        MessageListMenuItem.POST_TIME_DESC
      ];
      const paginationHelper =
        isTopic &&
        PaginationHelper.fromQueryResult<MessageRepliesQuery, MessageRepliesQueryVariables>(
          queryResult,
          pageSize,
          'message.replies',
          onUpdate
        );
      const showMorePreviousPagerVariant = isTopic && {
        type: PagerVariant.LOAD_MORE_PREVIOUS_NEXT_LINKABLE,
        props: {
          ...pagerVariant.props,
          paginationDirection: PaginationDirection.PREV,
          queryParams,
          last: topicRepliesFirst
        }
      };

      return (
        useHeader && (
          <>
            {message.repliesCount !== 0 && (
              <div className={cx('lia-replies-header')} ref={replyHeaderRef}>
                <ListTitle as="h3" className={cx('lia-replies-title')}>
                  {formatMessage('title', {
                    count: message.repliesCount
                  })}
                </ListTitle>
                <MessageListMenu
                  items={messageListMenuItems}
                  onChange={handleListMenuChange}
                  defaultItem={sortMenuItem}
                  className={cx('lia-replies-sort-menu')}
                  queryParameterName={EndUserQueryParams.SORT_MENU_QUERY_PARAMETER}
                  alignRight
                />
              </div>
            )}
            {message.readOnly && message.repliesCount !== 0 && (
              <div className={cx('lia-read-only-warning rounded-sm')}>
                <Icon
                  icon={Icons.LockIcon}
                  size={IconSize.PX_16}
                  color={IconColor.INFO}
                  className={cx('lia-read-only-warning-icon')}
                />
                <small>{formatMessage(`messageReadOnlyAlert:${conversationStyle}`)}</small>
              </div>
            )}
            {isTopic && paginationHelper?.pageInfo?.hasPreviousPage && (
              <Pager
                loadPage={paginationHelper.loadPage}
                pageInfo={paginationHelper.pageInfo}
                variant={showMorePreviousPagerVariant}
                className={'border-bottom-0 lia-g-ml-md-25 lia-g-mb-0'}
              />
            )}
            {useThreadSummary && <ThreadSummary type={conversationStyle} threadId={message.uid} />}
          </>
        )
      );
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cx, formatMessage, handleListMenuChange, message, sortMenuItem, useHeader]
  );

  if (textLoading || repliesSortOrderLoading) {
    return null;
  }

  return (
    <PaneledItemList<
      MessageViewFragment,
      ItemType.MESSAGE,
      ItemViewTypeAndProps<ItemType.MESSAGE, MessageViewVariant>,
      MessageRepliesQuery,
      MessageRepliesQueryVariables
    >
      type={ItemType.MESSAGE}
      variant={viewVariantState}
      queryResult={queryResult}
      pageSize={topicRepliesFirst}
      listVariant={listVariant}
      pagerVariant={threadedPagerVariant}
      onUpdate={onUpdate}
      itemPath="message.replies"
      className={cx('lia-g-will-load', reloading ? 'lia-g-is-reloading' : 'lia-g-is-loaded')}
      useHeaderWhenEmpty={false}
      header={renderHeader}
      empty={renderNoReplies}
      useEmpty={useEmpty}
      panel={PanelType.NONE}
      ariaLiveRegions={AriaLiveRegions.POLITE}
    />
  );
};

export default ThreadedReplyList;
