import { useApolloClient } from '@apollo/client';
import Button from '@aurora/shared-client/components/common/Button/Button';
import { ButtonVariant } from '@aurora/shared-client/components/common/Button/enums';
import { IconSize } from '@aurora/shared-client/components/common/Icon/enums';
import Icon from '@aurora/shared-client/components/common/Icon/Icon';
import {
  ToastAlertVariant,
  ToastVariant
} from '@aurora/shared-client/components/common/ToastAlert/enums';
import type ToastProps from '@aurora/shared-client/components/common/ToastAlert/ToastAlertProps';
import useFrameEnd, {
  getParentFrameId
} from '@aurora/shared-client/components/context/AnalyticsParentFrames/useFrameEnd';
import TenantContext from '@aurora/shared-client/components/context/TenantContext';
import useToasts from '@aurora/shared-client/components/context/ToastContext/useToasts';
import useMessagePolicies from '@aurora/shared-client/components/messages/useMessagePolicies';
import useMutationWithTracing from '@aurora/shared-client/components/useMutationWithTracing';
import useRegistrationStatus from '@aurora/shared-client/components/users/useRegistrationStatus';
import { hasErrors } from '@aurora/shared-client/helpers/apollo/ApolloHelper';
import Icons from '@aurora/shared-client/icons';
import type {
  Message,
  AcceptedSolutionMessage,
  Conversation,
  ReplyMessage
} from '@aurora/shared-generated/types/graphql-schema-types';
import { RepliesFormat } from '@aurora/shared-generated/types/graphql-schema-types';
import { EndUserComponent } from '@aurora/shared-types/pages/enums';
import IdConverter from '@aurora/shared-utils/graphql/IdConverter/IdConverter';
import { checkPolicy } from '@aurora/shared-utils/helpers/objects/PolicyResultHelper';
import { getLog } from '@aurora/shared-utils/log';
import React, { useContext } from 'react';
import { Badge, useClassNameMapper } from 'react-bootstrap';
import {
  updateSolutionMessageCache,
  updateSolutionsListCache
} from '../../../helpers/messages/MessageHelper/MessageHelper';
import type {
  AcceptedSolutionButtonFragment,
  MarkMessageAsSolutionMutation,
  UnmarkMessageAsSolutionMutation
} from '../../../types/graphql-types';
import contextMessageQuery from '../../context/ContextMessage.query.graphql';
import { UrlObject, useContextObjectRefFromUrl } from '../../context/useContextObjectFromUrl';
import useTranslation from '../../useTranslation';
import {
  deepLinkMessageVar,
  updatedLocalStateForNewMessageVar,
  updatedMessageForDeepLinkVar
} from '../MessageDeepLink/MessageReactVarHelper';
import localStyles from './AcceptedSolutionButton.module.css';
import markSolutionMutation from './MarkMessageAsSolution.mutation.graphql';
import unmarkSolutionMutation from './UnmarkMessageAsSolution.mutation.graphql';

const log = getLog(module);

interface Props {
  /**
   * The message.
   */
  message: AcceptedSolutionButtonFragment;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * Callback to update the messages in cache/local memory
   * @param message
   */
  onUpdate?: (message: Message) => void;
  /**
   * RepliesFormat of the discussion style.
   * Can be either threaded or linear.
   */
  repliesFormat?: RepliesFormat;
}

/**
 * If the edit button will be shown
 *
 * @param message - Message to render the button for.
 * @param canAcceptSolution - Permissions for the current user if they can view button.
 * @param canRejectSolution - Permissions for the current user if they can view button.
 */
function shouldRenderEditButton(
  message: AcceptedSolutionButtonFragment,
  canAcceptSolution: boolean,
  canRejectSolution: boolean
): boolean {
  return (
    (canAcceptSolution && message?.conversation?.solved === false) ||
    (canRejectSolution && (message as AcceptedSolutionMessage)?.solution)
  );
}

/**
 * If the button will be shown
 *
 * @param message - Message to render the button for.
 */
function shouldRenderButton(message: AcceptedSolutionButtonFragment): boolean {
  return (
    (message as AcceptedSolutionMessage).solution ||
    (message.depth === 0 && message.conversation.solved)
  );
}
/**
 * A button allowing the end user to mark or unmark a message as an accepted solution.
 * @constructor
 *
 * @author Dolan Halbrook
 */
const AcceptedSolutionButton: React.FC<React.PropsWithChildren<Props>> = ({
  className,
  message,
  onUpdate,
  repliesFormat = RepliesFormat.Threaded
}) => {
  const { formatMessage, loading: textLoading } = useTranslation(
    EndUserComponent.ACCEPTED_SOLUTION_BUTTON
  );
  const cx = useClassNameMapper(localStyles);
  const { addToast } = useToasts();
  const tenant = useContext(TenantContext);
  const { isAnonymous } = useRegistrationStatus();
  const isRootMessage = message.depth === 0;
  const client = useApolloClient();
  const { id: replyId } = useContextObjectRefFromUrl(UrlObject.REPLY);
  const isPermalinkReply = replyId !== null;

  const [markMessage] = useMutationWithTracing<MarkMessageAsSolutionMutation>(
    module,
    markSolutionMutation
  );
  const [unmarkMessage] = useMutationWithTracing<UnmarkMessageAsSolutionMutation>(
    module,
    unmarkSolutionMutation
  );
  const [frameEnd] = useFrameEnd();

  const { id, solution } = message as AcceptedSolutionMessage;

  // useCache is set to false on this component to get the
  // latest message policy values from network once any action is performed.
  const { data: policiesData, loading: policiesLoading } = useMessagePolicies(
    module,
    {
      id: message.id,
      useCanAcceptSolution: true,
      useCanRejectSolution: true
    },
    isAnonymous || IdConverter.isOptimistic(tenant, message?.id),
    false,
    false
  );

  const canAcceptSolution = checkPolicy(policiesData?.message?.messagePolicies?.canAcceptSolution);
  const canRejectSolution = checkPolicy(policiesData?.message?.messagePolicies?.canRejectSolution);

  if (textLoading || !message || policiesLoading) {
    return null;
  }

  function renderError(textKey: string, errorId: string): void {
    const formattedErrorId = `message-${errorId}-accepted-solution-${textKey}`;

    const toastProps: ToastProps = {
      toastVariant: ToastVariant.BANNER,
      alertVariant: ToastAlertVariant.DANGER,
      title: formatMessage('errorHeader'),
      message: formatMessage(textKey),
      autohide: true,
      id: formattedErrorId
    };
    addToast(toastProps);
  }

  /**
   * Updates the local state for permalink reply using reactive var.
   * @param isSolved message's current solved status.
   * @param conversation conversation the message is of.
   */
  function updateDeepLinkVar(isSolved: boolean, conversation: Conversation): void {
    const deepLinkMessageInStore = deepLinkMessageVar();
    const parentMessage = {
      ...(deepLinkMessageInStore as ReplyMessage)?.parent,
      conversation: conversation
    };
    if (onUpdate || deepLinkMessageInStore.id !== message.id) {
      updatedMessageForDeepLinkVar({
        ...deepLinkMessageInStore,
        parent: parentMessage,
        conversation
      } as unknown as Message);
    } else {
      updatedMessageForDeepLinkVar({
        ...message,
        parent: parentMessage,
        solution: isSolved,
        conversation
      } as unknown as Message);
    }
  }

  /**
   * Updates the cached reply's solution field.
   * As it is not sure which type of reply (ForumReplyMessage or AcceptedSolutionMessage)
   * is present in cache at a time as multiple components on UI may have its own type
   * for eg; MessageSolutionList have AcceptedSolutionMessage type
   * MessageReplies have ForumReplyMessage if not solved
   * RecentContentWidget have ForumReplyMessage, it will update the both the types.
   */
  function updateCacheForMessage(markSolved: boolean): void {
    client.cache.modify({
      id: `ForumReplyMessage:${id}`,
      fields: {
        solution() {
          return markSolved;
        }
      }
    });
    client.cache.modify({
      id: `AcceptedSolutionMessage:${id}`,
      fields: {
        solution() {
          return markSolved;
        }
      }
    });
  }

  async function handleEvent(): Promise<void> {
    const parentFrameId = getParentFrameId();
    if (!solution && canAcceptSolution) {
      log.info('Marking message %s as solution', id);

      const { errors } = await markMessage({
        variables: { messageId: id },
        update: cache => {
          updateSolutionsListCache(tenant, cache, message, true, repliesFormat);
          updateSolutionMessageCache(cache, message, true);
        },
        context: { parentFrameId },
        onCompleted: (d): void => {
          if (!hasErrors(d.markMessageAsSolution)) {
            // This method is used to update the cache objects. (will not work for new messages in local state).
            updateCacheForMessage(true);
            const conversation = { ...message.conversation, solved: true };
            // This block is used for handling new messages use case.
            if (onUpdate) {
              // if the message view which is calling is rendering this component from threadedreplylist
              // callback will be available to udpate the new messages local state.
              if (isPermalinkReply) {
                updateDeepLinkVar(true, conversation as Conversation);
              }
              onUpdate({
                ...message,
                solution: true,
                conversation
              } as unknown as Message);
            } else {
              // On Update callback will not be available when this component is used in message view
              // while rendering message solution list on topic message,
              // hence using this react var to update the message with latest.
              // The listener is present on message view so it will re render with latest info.
              // Please reset the value to null once we capture the latest info on message view
              // otherwise it may result in unnecesary renders as the listener is on all replies
              if (isPermalinkReply) {
                updateDeepLinkVar(true, conversation as Conversation);
              } else {
                updatedLocalStateForNewMessageVar({
                  ...message,
                  solution: true,
                  conversation
                } as unknown as Message);
              }
            }
            frameEnd({
              context: { parentFrameId }
            });
          }
        },
        refetchQueries: [
          {
            query: contextMessageQuery,
            variables: {
              id: id,
              useHoverCard: false
            }
          }
        ]
      });
      if (errors?.length > 0) {
        log.error('Unable to mark message as solution: %O', errors);
        renderError('errorAdd', id);
      }
    } else if (solution && canRejectSolution) {
      log.info('Unmarking message %s as solution', id);

      const { errors } = await unmarkMessage({
        variables: { messageId: id },
        update: cache => {
          updateSolutionsListCache(tenant, cache, message, false, repliesFormat);
          updateSolutionMessageCache(cache, message, false);
        },
        context: { parentFrameId },
        onCompleted: (d): void => {
          if (!hasErrors(d.unmarkMessageAsSolution)) {
            // This method is used to update the cache objects. (will not work for new messages in local state).
            updateCacheForMessage(false);
            const conversation = { ...message.conversation, solved: false };
            // This block is used for handling new messages use case.
            if (onUpdate) {
              // if the message view which is calling is rendering this component from threadedreplylist
              // callback will be available to udpate the new messages local state.
              if (isPermalinkReply) {
                updateDeepLinkVar(false, conversation as Conversation);
              }
              onUpdate({
                ...message,
                solution: false,
                conversation
              } as unknown as Message);
            } else {
              // On Update callback will not be available when this component is used in message view
              // while rendering message solution list on topic message,
              // hence using this react var to update the message with latest.
              // The listener is present on message view so it will re render with latest info.
              // Please reset the value to null once we capture the latest info on message view
              // otherwise it may result in unnecesary renders as the listener is on all replies
              if (isPermalinkReply) {
                updateDeepLinkVar(false, conversation as Conversation);
              } else {
                updatedLocalStateForNewMessageVar({
                  ...message,
                  solution: false,
                  conversation
                } as unknown as Message);
              }
            }
            frameEnd({
              context: { parentFrameId }
            });
          }
        },
        refetchQueries: [
          {
            query: contextMessageQuery,
            variables: {
              id: id,
              useHoverCard: false
            }
          }
        ]
      });
      if (errors?.length > 0) {
        log.error('Unable to unmark message as solution: %O', errors);
        // rollbacks.forEach(rollback => rollback());
        renderError('errorRemove', id);
      }
    }
  }

  if (shouldRenderEditButton(message, canAcceptSolution, canRejectSolution)) {
    return (
      <Button
        variant={ButtonVariant.LINK}
        className={cx(className, 'lia-g-action-btn lia-solution-btn', {
          'lia-is-solution': solution
        })}
        onClick={handleEvent}
      >
        <span className={cx('lia-solution-text')}>
          {solution ? formatMessage('accepted') : formatMessage('accept')}
        </span>
        {solution && (
          <Icon icon={Icons.CloseIcon} className={cx('lia-close-icon')} size={IconSize.FROM_CSS} />
        )}
      </Button>
    );
  } else if (shouldRenderButton(message) && !isRootMessage) {
    return (
      <Button
        variant={ButtonVariant.LINK}
        disabled
        className={cx(className, 'lia-g-action-btn lia-solution-btn lia-is-solution')}
      >
        <span className={cx('lia-solution-text')}>{formatMessage('accepted')}</span>
      </Button>
    );
  } else if (shouldRenderButton(message) && isRootMessage) {
    return (
      <Badge pill className={cx('lia-solved-badge', className)}>
        {formatMessage('solved')}
      </Badge>
    );
  }
  return null;
};

export { shouldRenderEditButton, shouldRenderButton };
export default AcceptedSolutionButton;
